import { DateTime, Interval } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import { FaCircle } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import styled from "styled-components";

import {
  NOTIFICATION_MEETING_CANCELLED,
  NOTIFICATION_NEW_MEETING,
  NOTIFICATION_WELCOME,
} from "../../consts";
import { useAuth } from "../../context/AuthContext";
import notification from "../../services/notification";
import UserNotificationDataService from "../../services/userNotification";

export default function UserNotifications({
  show,
  position,
  setUnread,
  isAppBarShowing,
  snapshot,
  loading,
}) {
  const [notifications, setNotifications] = useState();

  const notificationsRef = useRef();
  const { currentUser } = useAuth();

  function parseNotification(timestamp, notification) {
    if (!notification) {
      return;
    }
    var result = {
      unread: notification.unread,
      type: notification.type,
    };

    var date = new DateTime.fromJSDate(new Date(Number(timestamp)));

    let dayInterval = Interval.fromDateTimes(
      DateTime.local().minus({ days: 1 }),
      DateTime.local()
    );
    let weekInterval = Interval.fromDateTimes(
      DateTime.local().minus({ weeks: 1 }),
      DateTime.local()
    );

    let monthlyInterval = Interval.fromDateTimes(
      DateTime.local().minus({ months: 1 }),
      DateTime.local().endOf("week")
    );
    if (!monthlyInterval.contains(date)) {
      result.period = "none";
      return;
    } else if (dayInterval.contains(date)) {
      result.period = "day";
      var parsedTime = date.diff(DateTime.local(), "hours");
      result.parsedTime = parseInt(Math.abs(parsedTime.hours)) + " hours ago";
    } else if (weekInterval.contains(date)) {
      result.period = "week";
      var parsedTime = date.diff(DateTime.local(), "days");
      result.parsedTime = parseInt(Math.abs(parsedTime.days)) + " days ago";
    } else {
      result.period = "month";
      var parsedTime = date.diff(DateTime.local(), "weeks");
      result.parsedTime = parseInt(Math.abs(parsedTime.weeks)) + " weeks ago";
    }

    switch (notification.type) {
      case NOTIFICATION_NEW_MEETING:
        return {
          ...result,
          icon: <IoIosAdd />,
          message:
            "New meeting with tutor " +
            (notification.data.tutorName ?? " ") +
            " on " +
            (notification.data.date ?? " day ") +
            " at " +
            (notification.data.hour ?? " hour."),
        };

      case NOTIFICATION_MEETING_CANCELLED:
        return {
          ...result,
          icon: <IoIosAdd />,
          message:
            "Meeting with tutor " +
            (notification.data.tutorName ?? " ") +
            " was cancelled",
        };
      case NOTIFICATION_WELCOME:
        return {
          ...result,
          icon: <AiOutlineStar />,
          message: "Welcome to Hop On!",
        };
    }
    return {
      ...result,
      icon: <IoIosAdd />,
      message: "New Notification with very long text that is long",
      unread: false,
    };
  }
  function parseNotifications(notifications) {
    if (!notifications) {
      return;
    }
    var result = [];
    const orderedNotifications = Object.keys(notifications)
      .sort()
      .reverse()
      .reduce((obj, key) => {
        obj[key] = notifications[key];
        return obj;
      }, {});
    var qtaUnread = 0;
    Object.entries(orderedNotifications).map(([timestamp, notification], i) => {
      if (notification.unread) {
        qtaUnread += 1;
      }
      var res = parseNotification(timestamp, notification);

      if (res) {
        result.push(res);
      }
    });

    setUnread(qtaUnread);
    setNotifications(result);
  }
  const fetchNotifications = async () => {
    const response = await UserNotificationDataService.get(
      currentUser?.uid ?? "undefined"
    );

    parseNotifications(response.data());
    console.log(response.data());
  };
  useEffect(() => {
    if (snapshot && snapshot.docs[0] && !loading) {
      parseNotification(snapshot.docs[0].data());
    }
  }, [snapshot, loading]);

  function makeAllRead(notifications) {
    if (!notifications) return;
    Object.entries(notifications).map(([k, v], i) => {
      if (k != "unreadMessages") {
        v.unread = false;
      }
    });

    return notifications;
  }
  useEffect(() => {
    const fetchAndMarkNotifications = async () => {
      if (show) {
        const response = await UserNotificationDataService.get(currentUser.uid);
        if (response.exists()) {
          UserNotificationDataService.update(
            currentUser.uid,
            makeAllRead(response.data())
          );
        }
      }
    };
    fetchNotifications().then(() => fetchAndMarkNotifications());
  }, [show]);

  function getYPosition() {
    if (notificationsRef.current) {
      if (isAppBarShowing && position.y) {
        return (
          position.y - notificationsRef.current.getBoundingClientRect().height
        );
      }
    }
    return position?.y ?? 0;
  }
  return (
    <UserNotificationsWrapper
      show={show}
      positionX={position?.x ?? 0}
      positionY={getYPosition()}
      ref={notificationsRef}
    >
      <div className="title">Notifications </div>
      {(!notifications || notifications.length == 0) && (
        <UserNotification>No notifications yet. </UserNotification>
      )}
      {notifications &&
        notifications.map((obj, i) => (
          <UserNotification key={i}>
            {obj && obj.unread == true && (
              <div className="unread">
                <FaCircle />
              </div>
            )}
            <div className="icon">{obj && obj.icon}</div>
            <div className="message">{obj && obj.message}</div>
            <div className="date">{obj && obj.parsedTime}</div>
          </UserNotification>
        ))}
    </UserNotificationsWrapper>
  );
}
export const UserNotification = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2rem;
  margin-top: 0.8rem;
  position: relative;
  min-height: 80px;
  .icon {
    font-size: 2rem;
    line-height: 2rem;
    width: 35px;
  }
  .message {
  }
  .unread {
    color: #f08846;
    font-size: 0.8rem;
    line-height: 2rem;
  }

  .date {
    position: absolute;
    bottom: 3px;
    right: 30px;
    font-size: 0.8rem;
  }
`;
export const UserNotificationsWrapper = styled.div`
  position: fixed;
  left: ${(props) => props.positionX + "px" ?? 0};
  top: ${(props) => props.positionY + "px" ?? 0};
  background-color: var(--white-text);
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 2rem;
  padding: 1rem;
  display: block;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  z-index: 9000;
  min-height: 100px;

  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

  max-height: 500px;
  overflow: scroll;
  .title {
    font-size: 1.4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
`;

import {
  SECTION_COLOR_ALL,
  SECTION_COLOR_CRIT_READING,
  SECTION_COLOR_GRAMMAR,
  SECTION_COLOR_VOCABULARY,
  SECTION_TYPE_CRIT_READING,
  SECTION_TYPE_GRAMMAR,
  SECTION_TYPE_VOCABULARY,
} from "./consts";

const { DateTime, Interval } = require("luxon");

export function parsePrice(price) {
  return "R$" + price.slice(0, -2) + "," + price.slice(-2);
}

export function* days(interval) {
  let cursor = interval.start.startOf("day");
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}
export const getSectionColor = (type) => {
  switch (type) {
    case SECTION_TYPE_CRIT_READING:
      return SECTION_COLOR_CRIT_READING;

    case SECTION_TYPE_GRAMMAR:
      return SECTION_COLOR_GRAMMAR;

    case SECTION_TYPE_VOCABULARY:
      return SECTION_COLOR_VOCABULARY;

    default:
      return SECTION_COLOR_ALL;
  }
};
export const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
export function parseTimeSpent(seconds) {
  if (isNaN(parseInt(seconds % 60)) || isNaN(parseInt((seconds / 60) % 60))) {
    return undefined;
  }
  let s = parseInt(seconds % 60)
    .toString()
    .padStart(2, "0");
  let m = parseInt((seconds / 60) % 60)
    .toString()
    .padStart(2, "0");
  return m + ":" + s;
}
export function getWeek(offset) {
  let start = DateTime.local()
    .plus({ weeks: offset })
    .startOf("week");
  let end = DateTime.local()
    .plus({ weeks: offset })
    .endOf("week");
  let interval = Interval.fromDateTimes(start, end);
  var arr = Array.from(days(interval));

  return arr;
}
export function ISODateString(d) {
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  return (
    d.getUTCFullYear() +
    "-" +
    pad(d.getUTCMonth() + 1) +
    "-" +
    pad(d.getUTCDate()) +
    "T" +
    pad(d.getUTCHours()) +
    ":" +
    pad(d.getUTCMinutes()) +
    ":" +
    pad(d.getUTCSeconds()) +
    "Z"
  );
}

export function toStars(grade) {
  if (grade < 0.3) {
    return 0;
  } else if (grade < 0.6) {
    return 1;
  } else if (grade < 0.9) {
    return 2;
  } else {
    return 3;
  }
}

export function toPerc(grade) {
  return (grade * 100).toFixed(1);
}

import { combineReducers, createStore } from "redux";
import courseReducer from "./redux/course";
import contentViewReducer from "./redux/contentView";
import productReducer from "./redux/product";
import studentActivityReducer from "./redux/studentActivity";
import multipleChoiceUIReducer from "./redux/multipleChoiceUI";
import roleReducer from "./redux/role";
import { profileReducer } from "./redux/profile";
import tutorialReducer from "./redux/tutorial";
const RESET_STATE = "app/reset_state";

export const resetState = () => ({
  type: RESET_STATE,
});

const rootReducer = combineReducers({
  course: courseReducer,
  product: productReducer,
  contentView: contentViewReducer,
  studentActivity: studentActivityReducer,
  multipleChoiceUI: multipleChoiceUIReducer,
  role: roleReducer,
  profile: profileReducer,
  tutorial: tutorialReducer,
});

const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
);

store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});
export default store;

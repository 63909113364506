import React from "react";
import styled from "styled-components";
import { nanoid } from "nanoid";
export default function Checkbox({ set, value, disabled }) {
  const id = nanoid();
  return (
    <>
      {disabled && <h5>Comming soon</h5>}
      {!disabled && (
        <CheckboxStyle checkd={"_checkbox" + id}>
          <input
            disabled={disabled}
            type="checkbox"
            checked={value}
            onChange={(e) => set(!value)}
            className={"_checkbox"}
            id={"_checkbox" + id}
          />
          <label for={"_checkbox" + id}>
            <div id="tick_mark"></div>
          </label>
        </CheckboxStyle>
      )}
    </>
  );
}
const CheckboxStyle = styled.div`
  -webkit-tap-highlight-color: transparent;
  outline: none;

  ._checkbox {
    display: none;
  }

  label {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-color: #f72414;
    transform: translateY(-50%);
    border-radius: 50%;
    /*box-shadow: 0 3px 10px #ffbeb8;*/
    cursor: pointer;
    transition: 0.2s ease transform, 0.2s ease background-color,
      0.2s ease box-shadow;
    overflow: hidden;
    z-index: 1;
  }

  label:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 42px;
    height: 42px;
    margin: 0 auto;
    background-color: #fff;
    transform: translateY(-50%);
    border-radius: 50%;
    /*box-shadow: inset 0 3px 10px #ffbeb8;*/
    transition: 0.2s ease width, 0.2s ease height;
  }

  label:hover:before {
    width: 38px;
    height: 38px;
    /*box-shadow: inset 0 3px 10px #ff9d96;*/
  }

  label:active {
    transform: translateY(-50%) scale(0.9);
  }

  #tick_mark {
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    margin-left: 7px;
    transform: rotateZ(-40deg);
  }

  #tick_mark:before,
  #tick_mark:after {
    content: "";
    position: absolute;
    background-color: #fff;
    border-radius: 2px;
    opacity: 0;
    transition: 0.2s ease transform, 0.2s ease opacity;
  }

  #tick_mark:before {
    left: 0;
    bottom: 0;
    width: 5px;
    height: 15px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
    transform: translateY(-68px);
  }

  #tick_mark:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    /*box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23);*/
    transform: translateX(39px);
  }

  ._checkbox:checked + label {
    background-color: #07d410;
    /*box-shadow: 0 3px 10px #92ff97;*/
  }

  ._checkbox:checked + label:before {
    width: 0;
    height: 0;
  }

  ._checkbox:checked + label #tick_mark:before,
  ._checkbox:checked + label #tick_mark:after {
    transform: translate(0);
    opacity: 1;
  }
`;

import React from "react";
import { useLottie } from "lottie-react";
import styled from "styled-components";

import animationData from "../assets/animation/loading.json";

export default function Loading({ inline }) {
  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(options);
  return <LoadingRing inline={inline}>{View}</LoadingRing>;
}

const LoadingRing = styled.div`
  position: ${(props) => (props.inline ? "relative" : "fixed")};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  top: 40%;
  height: 30vw;
  width: 30vw;
  @media (max-width: 769px) {
    left: 0;
    height: 60vw;
    width: 60vw;
  }
  @media (max-width: 500px) {
    left: 0;
    height: 80vw;
    width: 80vw;
  }
`;

import "../styles/LandingPage.css";

import React, { useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import diagnostics from "../assets/icons/Diagnostics.png";
import essay from "../assets/icons/Essay.png";
import hat from "../assets/icons/Hat.png";
import measure from "../assets/icons/measure.png";
import medal from "../assets/icons/Medal.png";
import teacher from "../assets/icons/teacher.png";
import Journey1 from "../assets/images/journey/journey_01.png";
import Journey2 from "../assets/images/journey/journey_02.png";
import Journey3 from "../assets/images/journey/journey_03.png";
import Journey4 from "../assets/images/journey/journey_04.png";
import Journey5 from "../assets/images/journey/journey_05.png";
import Journey6 from "../assets/images/journey/journey_06.png";
import Journey7 from "../assets/images/journey/journey_07.png";
import Journey8 from "../assets/images/journey/journey_08.png";
import Journey9 from "../assets/images/journey/journey_09.png";
import Journey10 from "../assets/images/journey/journey_10.png";
import { US, BR } from "country-flag-icons/react/3x2";

import iconMeeting from "../assets/icons/iconMeeting.png";
import iconUpdate from "../assets/icons/iconUpdate.png";
import iconCommunity from "../assets/icons/iconCommunity.png";
import logo from "../assets/images/Logo.svg";
import run from "../assets/images/smartle_landing.png";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { MdTouchApp } from "react-icons/md";
import { Trans, useTranslation } from "react-i18next";
import {
  CardContainer,
  SubscriptionCard,
} from "./Onboarding/SubscriptionSelectForm";

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const [modalJourneyText, setModalJourneyText] = useState("");
  const [modalJourneyTitle, setModalJourneyTitle] = useState("");
  const [modalJourneyPosition, setModalJourneyPosition] = useState({
    x: 0,
    y: 0,
    width: 0,
  });
  const [journeyDisplacement, setJourneyDisplacement] = useState(0);
  const [showModalJourney, setShowModalJourney] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const logoRef = useRef();
  const divRef = useRef();
  const modalJourney = useRef();
  const journeyImage = useRef();
  const onScroll = () => {
    const topPos = divRef.current.getBoundingClientRect().top;

    if (topPos < 0) {
      logoRef.current.classList.remove("inactive");
      logoRef.current.classList.add("active");
    } else {
      logoRef.current.classList.add("inactive");
      logoRef.current.classList.remove("active");
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("mousedown", () => setShowModalJourney(false));

    return () => {
      window.removeEventListener("mousedown", () => setShowModalJourney(false));

      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  function onShowModalJourney(e, number) {
    setShowModalJourney(true);

    switch (number) {
      case 0:
        setModalJourneyTitle(t("journey_landing.title_1"));
        setModalJourneyText(t("journey_landing.step_1"));
        break;
      case 1:
        setModalJourneyTitle(t("journey_landing.title_2"));
        setModalJourneyText(t("journey_landing.step_2"));
        break;
      case 2:
        setModalJourneyTitle(t("journey_landing.title_3"));
        setModalJourneyText(t("journey_landing.step_3"));
        break;
      case 3:
        setModalJourneyTitle(t("journey_landing.title_4"));
        setModalJourneyText(t("journey_landing.step_4"));
        break;
      case 4:
        setModalJourneyTitle(t("journey_landing.title_5"));
        setModalJourneyText(t("journey_landing.step_5"));
        break;
      case 5:
        setModalJourneyTitle(t("journey_landing.title_6"));
        setModalJourneyText(t("journey_landing.step_6"));
        break;
    }
    var x =
      e.target.getBoundingClientRect().left -
      modalJourney.current.offsetWidth / 2;

    setModalJourneyPosition({
      x: x,

      y:
        window.pageYOffset +
        e.target.getBoundingClientRect().top -
        modalJourney.current.offsetHeight,
      width: modalJourney.current.offsetWidth,
    });
  }
  function changeLanguage(e) {
    i18n.changeLanguage(i18n.language == "en" ? "pt-BR" : "en");
  }
  return (
    <div className="landing-container">
      <header className="header">
        <img
          className="logo-landing inactive"
          src={logo}
          alt=""
          ref={logoRef}
        />
        <input type="checkbox" id="nav-toggle" className="nav-toggle" />
        <nav className="navbar">
          <ul>
            <li>
              <a className="link" href="#Platform">
                {t("landing.nav_1")}
              </a>
            </li>{" "}
            <li>
              <a className="link" href="#Features">
                {t("landing.nav_2")}
              </a>
            </li>
            <li>
              <div>
                <a className="link" href="#div-empty">
                  {t("landing.nav_3")}
                </a>
              </div>
            </li>
            <li>
              <a className="link" href="#Diagnostic">
                {t("landing.nav_4")}
              </a>
            </li>{" "}
            <li>
              <a className="link" href="#Courses">
                {t("landing.nav_5")}
              </a>
            </li>{" "}
            <li>
              <button className=" flag-button link" onClick={changeLanguage}>
                {i18n.language == "en" ? (
                  <US title="Brasil" />
                ) : (
                  <BR title="United States" />
                )}
              </button>
            </li>
            <li>
              {currentUser && (
                <Link to="/home">
                  <button className="  button link" type="button">
                    {t("landing.nav_6")}
                  </button>
                </Link>
              )}
              {!currentUser && (
                <Link to="/login" className="link">
                  <button className="button link " type="button">
                    {t("landing.nav_7")}
                  </button>
                </Link>
              )}
            </li>
          </ul>
        </nav>
        <label htmlFor="nav-toggle" className="nav-toggle-label">
          <span />
        </label>
      </header>
      <span id="Plataform" />
      <section className="first-section">
        <img src={run} alt="" className="smartle-running" />
        <div className="introduction-text">
          <h3 className="title-section-hop-on">
            <b>
              <img src={logo} className="logo-intro" />
              <br /> {t("landing.and_lear_for_real")}
            </b>
          </h3>
          <p>
            {t("landing.intro_text")}
            <br />
            <br />
            {!currentUser && (
              <button
                style={{ padding: "1.5rem 2rem", lineHeight: "0" }}
                className="button"
                onClick={() => navigate("/signup")}
              >
                {t("landing.sign_up")}
              </button>
            )}
            {currentUser && (
              <button
                style={{ padding: "1.5rem 2rem", lineHeight: "0" }}
                className="button"
                onClick={() => navigate("/home")}
              >
                Dashboard
              </button>
            )}
            <br />
            <br />
            <br />
          </p>
        </div>
      </section>
      <span id="Features" />
      <section className="fourth-section" ref={divRef}>
        <div className="college">
          <img src={measure} alt="" />
          <h3>
            <b> {t("landing.card_heading_1")}</b>
          </h3>
          <p className="text-fourth-college">{t("landing.card_content_1")}</p>
        </div>
        <div className="essay">
          <img src={teacher} alt="" />
          <h3>
            <b> {t("landing.card_heading_2")}</b>
          </h3>
          <p className="text-fourth-college">{t("landing.card_content_2")}</p>
        </div>
        <div className="diagnostics">
          <img src={diagnostics} alt="" />
          <h3>
            <b> {t("landing.card_heading_3")}</b>
          </h3>
          <p>{t("landing.card_content_3")}</p>
        </div>
      </section>
      <span id="Diagnostic" />
      <section className="diagnostic-section">
        <div className="div-test-diagnostic">
          <h1>{t("landing.diagnostic_heading")}</h1>
          <p className="about-diagnostic">
            <Trans>{t("landing.diagnostic_content")}</Trans>
          </p>
        </div>
        <div className="div-button-ld-diagnostic">
          <a href="/signup" className="link-diagnostic">
            <h1>
              <b>{t("landing.sign_up")}</b>
            </h1>
          </a>
        </div>
      </section>{" "}
      <span id="Journey" />
      <ModalJourney
        ref={modalJourney}
        positionX={Math.min(
          window.Math.max(modalJourneyPosition.x, 0),
          window.innerWidth - modalJourneyPosition.width
        )}
        positionY={modalJourneyPosition.y}
        show={showModalJourney}
      >
        <h5 id="center-content">{modalJourneyTitle}</h5>
        {modalJourneyText}
      </ModalJourney>
      <section className="journey-section">
        <div className="div-journey">
          <div className="div-text-journey">
            <h1>
              <b>{t("landing.journey_heading")}</b>
            </h1>
            <p>
              <Trans>{t("landing.journey_subtitle")}</Trans>
            </p>
          </div>
          <div className="div-img-journey">
            <JourneyContainer displacement={journeyDisplacement}>
              {journeyDisplacement != 0 && (
                <div
                  className="arrow left"
                  onClick={() =>
                    setJourneyDisplacement((value) =>
                      Math.min(
                        value +
                          journeyImage.current.getBoundingClientRect().width *
                            0.2,
                        0
                      )
                    )
                  }
                >
                  <BiLeftArrow />
                </div>
              )}

              <div className="journeyContainer" ref={journeyImage}>
                <img
                  src={Journey1}
                  alt="cartoon of the journey"
                  className="img-cartoon hashover"
                  onClick={(e) => onShowModalJourney(e, 0)}
                  onMouseOver={(e) => onShowModalJourney(e, 0)}
                  onMouseOut={(e) => setShowModalJourney(false)}
                />
                <img
                  src={Journey2}
                  alt="cartoon of the journey"
                  className="img-cartoon hashover"
                  onClick={(e) => onShowModalJourney(e, 1)}
                  onMouseOver={(e) => onShowModalJourney(e, 1)}
                  onMouseOut={(e) => setShowModalJourney(false)}
                />
                <img
                  src={Journey3}
                  alt="cartoon of the journey"
                  className="img-cartoon"
                />
                <img
                  src={Journey4}
                  alt="cartoon of the journey"
                  className="img-cartoon hashover"
                  onClick={(e) => onShowModalJourney(e, 2)}
                  onMouseOver={(e) => onShowModalJourney(e, 2)}
                  onMouseOut={(e) => setShowModalJourney(false)}
                />
                <img
                  src={Journey5}
                  alt="cartoon of the journey"
                  className="img-cartoon"
                />
                <img
                  src={Journey6}
                  alt="cartoon of the journey"
                  className="img-cartoon hashover"
                  onClick={(e) => onShowModalJourney(e, 3)}
                  onMouseOver={(e) => onShowModalJourney(e, 3)}
                  onMouseOut={(e) => setShowModalJourney(false)}
                />
                <img
                  src={Journey7}
                  alt="cartoon of the journey"
                  className="img-cartoon"
                />
                <img
                  src={Journey8}
                  alt="cartoon of the journey"
                  className="img-cartoon hashover"
                  onClick={(e) => onShowModalJourney(e, 4)}
                  onMouseOver={(e) => onShowModalJourney(e, 4)}
                  onMouseOut={(e) => setShowModalJourney(false)}
                />
                <img
                  src={Journey9}
                  alt="cartoon of the journey"
                  className="img-cartoon"
                />
                <img
                  src={Journey10}
                  alt="cartoon of the journey"
                  className="img-cartoon hashover"
                  onClick={(e) => onShowModalJourney(e, 5)}
                  onMouseOver={(e) => onShowModalJourney(e, 5)}
                  onMouseOut={(e) => setShowModalJourney(false)}
                />
              </div>
              {(!journeyImage.current ||
                (journeyImage.current &&
                  journeyDisplacement >
                    -journeyImage.current.getBoundingClientRect().width +
                      window.innerWidth)) && (
                <div
                  className="arrow right"
                  onClick={() =>
                    setJourneyDisplacement((value) =>
                      Math.max(
                        value -
                          journeyImage.current.getBoundingClientRect().width *
                            0.2,
                        -journeyImage.current.getBoundingClientRect().width +
                          window.innerWidth
                      )
                    )
                  }
                >
                  <BiRightArrow />
                </div>
              )}
            </JourneyContainer>
          </div>
        </div>
      </section>
      <div id="div-empty"> </div>
      <span id="Appraisal" />
      <section className="fifth-section">
        <div className="perfil1">
          <h3>
            <b>Enzo</b>
          </h3>
          <p>{t("landing.testimonial_1")}</p>
        </div>

        <div className="perfil3">
          <h3>
            <b>Raquel</b>
          </h3>
          <p>{t("landing.testimonial_2")}</p>
        </div>
        <div className="perfil4">
          <h3>
            <b>Nicolas</b>
          </h3>
          <p>{t("landing.testimonial_3")}</p>
        </div>
      </section>
      <span id="Courses" />
      <section className="sixth-section">
        <h1>{t("landing.products_title")}</h1>
        <div className="course-wrapper">
          <div className="toefl-course">
            <img src={essay} alt="" className="icon-course" />

            <h3>{t("landing.product_1_title")}</h3>
            <p className="about-plan">{t("landing.product_1_subtitle")}</p>
            <ul>
              <Trans components={<li />}>
                {t("landing.product_1_features")}
              </Trans>
            </ul>
          </div>
          <div className="sat-course">
            <img src={medal} alt="" className="icon-course" />
            <h3>{t("landing.product_2_title")}</h3>
            <p className="about-plan">{t("landing.product_2_subtitle")}</p>
            <ul>
              <Trans components={<li />}>
                {t("landing.product_2_features")}
              </Trans>
            </ul>
          </div>
          <div className="essay-course">
            <img src={hat} alt="" className="icon-course" />
            <h3>
              {" "}
              <Trans>{t("landing.product_3_title")} </Trans>
            </h3>
            <p className="about-plan">{t("landing.product_3_subtitle")}</p>
            <ul>
              <Trans components={<li />}>
                {t("landing.product_3_features")}
              </Trans>
            </ul>
          </div>
        </div>
      </section>
      <section className="plans">
        <h1>{t("landing.plans_title")}</h1>
        <p>
          <Trans>{t("landing.plans_subtitle")}</Trans>
          <br />
          <br />
        </p>
        <div className="products_container">
          <div className="container">
            <SubscriptionCard className="card ">
              <div className="left">
                <img className="icon" src={medal} />
                <div>
                  <div className="heading">{t("landing.plan_1_title")}</div>
                  <div className="priceMonth">R$100,00/month</div>
                  <div className="priceFull">R$200,00</div>
                </div>
              </div>
            </SubscriptionCard>
            <SubscriptionCard className="card">
              <div className="padded">
                <div className="heading">{t("landing.plan_2_title")}</div>
                <div className="priceMonth">R$100,00/month</div>{" "}
                <div className="priceFull">R$200,00</div>
              </div>
            </SubscriptionCard>
            <SubscriptionCard className="card">
              <div className="padded">
                <div className="heading">{t("landing.plan_3_title")}</div>
                <div className="priceMonth">R$100,00/month</div>{" "}
                <div className="priceFull">R$200,00</div>
              </div>
            </SubscriptionCard>
          </div>
        </div>
      </section>
      <section className="rights">
        <div className="allPlans">{t("landing.all_feat_title")}</div>
        <div className="iconList">
          <div className="iconGroup">
            <img src={iconUpdate} />
            <p>{t("landing.all_feat_1")}</p>
          </div>
          <div className="iconGroup">
            <img src={iconCommunity} /> <p>{t("landing.all_feat_2")}</p>
          </div>
          <div className="iconGroup">
            <img src={iconMeeting} />
            <p> {t("landing.all_feat_3")} </p>
          </div>
        </div>
      </section>
      <section className="footer">
        <p>
          {" "}
          {t("landing.all_rights_reserved")}
          <br />
          40.525.087/0001-35
        </p>
      </section>
    </div>
  );
};

export default LandingPage;

const ModalJourney = styled.div`
  position: absolute;
  width: 30vw;
  border-radius: 2rem;
  border: 1px solid var(--accent);
  padding: 1rem 2rem;
  background-color: var(--white-text);
  z-index: 899;
  top: ${(props) => props.positionY + "px" ?? 0};
  left: ${(props) => props.positionX + "px" ?? 0};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  @media (max-width: 768px) {
    width: 50vw;
  }
`;

const JourneyContainer = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  width: 100vw;

  .arrow {
    display: none;
  }

  .journeyContainer {
    margin: 0 auto;
    display: flex;
    height: 12vh;
  }
  img {
  }

  @media (max-width: 1308px) {
    .arrow {
      display: block;
      position: absolute;
      left: 0;
      bottom: 30%;
      font-size: 5rem;
      z-index: 1000;
    }
    .arrow > svg {
      -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    }
    .right {
      left: 80%;
    }
    .journeyContainer {
      position: absolute;
      left: ${(props) =>
        props.displacement ? props.displacement + "px" : "3rem"};
      top: 0;

      overflow: hidden;
      display: flex;
      height: 15vh;
    }
    height: 10rem;
    overflow: hidden;
  }
`;

import {
  runTutorial,
  setStepIndex,
  stopTour,
  stopTutorial,
} from "../redux/tutorial";

export const meetingTutorialCallback = (
  data,
  navigate,
  dispatch,
  onEndCallback,
  courseId
) => {
  const { action, index, lifecycle, type } = data;

  if (type === "step:after") {
    if (index >= 1 && action === "prev") {
      //dispatch(runTutorial());
      dispatch(setStepIndex(index - 1));
    } else if (action === "next") {
      //dispatch(runTutorial());
      dispatch(setStepIndex(index + 1));
    }
  }
  if (index == 5 && lifecycle === "complete") {
    onEndCallback();
    dispatch(stopTutorial());
    dispatch(setStepIndex(0));
    dispatch(stopTour());
  }
  if (action == "close") {
    onEndCallback();
    dispatch(stopTutorial());
    dispatch(setStepIndex(0));
    dispatch(stopTour());
  }
};
export const meetingTutorialSteps = [
  {
    target: ".column.right",
    content: (
      <>
        <p>Here you'll be able to manage your meetings.</p>
        <p>
          You’re going to have two meetings along the HopOn path. We suggest
          doing one at the beginning and another after you finish it.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: ".brighta",
    content: (
      <>
        <p>
          {" "}
          During the first meeting, one of our tutors will get to know your
          goals and help you draw a plan by providing you with proper
          instructions.
        </p>
      </>
    ),
  },
  {
    target: ".brighta",
    content: (
      <>
        <p>
          In the last meeting, one of our tutors will revisit all the work
          you've done so far and discuss your next steps.
        </p>
      </>
    ),
  },
  {
    target: ".schedulebutton.btn.btn-primary",
    content: (
      <>
        <p>
          You are responsible for scheduling your own meetings, so plan ahead
          and sync your calendar with our platform!
        </p>
      </>
    ),
  },
  {
    target: ".button-help",
    content: (
      <>
        <p>If you've missed something, I can tell all about meetings here.</p>
      </>
    ),
  },
];

import React from "react";
import { BsFillCalendarWeekFill, BsTrophy } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import { MdOutlineBookmarkAdded } from "react-icons/md";
import { MenuItem } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { useAuth } from "../../context/AuthContext";

export default function StudentSidebar() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course);
  const { courseId } = useParams();

  return (
    <>
      <ReactTooltip id="aaa" />
      <MenuItem
        onClick={(e) => navigate("/home")}
        icon={<GoHome />}
        data-tip="Home"
      >
        <Link to="/home">Home</Link>
      </MenuItem>
      {courseId && (
        <MenuItem
          data-tip="View Course Report"
          onClick={(e) => navigate("/c/" + courseId + "/activity")}
          icon={<MdOutlineBookmarkAdded />}
        >
          <Link to={"/c/" + courseId + "/activity"}>View Course Report</Link>
        </MenuItem>
      )}
      <MenuItem
        data-tip="Update Profile"
        onClick={(e) => navigate("/updateprofile")}
        icon={<CgProfile />}
      >
        <Link to="/updateprofile">Profile</Link>
      </MenuItem>
      <MenuItem
        data-tip="Achievements"
        onClick={() => navigate("/viewachievements")}
        icon={<BsTrophy />}
      >
        <Link to="/viewachievements">Achievements</Link>
      </MenuItem>

      <MenuItem
        data-tip="Tutor Meetings"
        id="tutorMeetings"
        onClick={() => navigate("/setmeeting")}
        icon={<BsFillCalendarWeekFill />}
      >
        <Link to="/setmeeting">Tutor Meetings</Link>
      </MenuItem>
      <MenuItem
        onClick={(e) => navigate("/logout")}
        icon={<FiLogOut />}
        data-tip="Sign Out"
      >
        <Link to="/logout"> Sign Out</Link>
      </MenuItem>
    </>
  );
}

import { collection, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "../../context/AuthContext";
import { firebase } from "../../firebase";
import ChatDataService from "../../services/chat";
import UserNotificationDataService from "../../services/userNotification";
import {
  UserNotification,
  UserNotificationsWrapper,
} from "../UserNotifications/UserNotifications";
import MiniChatContainer from "./MiniChatContainer";

export default function ChatNotifications({
  show,
  setShow,
  position,
  unread,
  setUnread,
  isAppBarShowing,
  userNotificationSnapshot,
  userNotificationLoading,
}) {
  const [chats, setChats] = useState([]);
  const [visibleMiniChats, setVisibleMiniChats] = useState([]);
  const { currentUser } = useAuth();
  const notificationsRef = useRef();
  const [chatIds, setChatIds] = useState([""]);
  const [forceShowChat, setForceShowChat] = useState("");
  const [numberPerChat, setNumberPerChat] = useState({});
  const [snapshot, loading, error] = useCollection(
    query(collection(firebase, "/chatMessage"), where("chat", "in", chatIds))
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userNotificationSnapshot &&
      userNotificationSnapshot.docs[0] &&
      !userNotificationLoading
    ) {
      const data = userNotificationSnapshot.docs[0].data();

      if (!data.unreadMessages) {
        UserNotificationDataService.update(currentUser.uid, {
          unreadMessages: {},
        });
      } else {
        setNumberPerChat(data.unreadMessages);
      }
    }
  }, [userNotificationSnapshot, userNotificationLoading]);
  useEffect(() => {
    const fetchChats = async () => {
      try {
        if (!currentUser) {
          navigate("/login");
        }
        const response = await ChatDataService.getFromUserId(currentUser.uid);
        // "" included to avoid firebase error.
        setChatIds(["", ...response.map((obj) => obj.id)]);
        setChats(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchChats();
  }, []);
  function parseMessages(messages) {
    var newUnread = 0;
    //console.log(storedNumberPerChat);
    //console.log(unread, newUnread);

    chatIds.map((id) => {
      if (id in numberPerChat && Number(numberPerChat[id]) > 0) {
        newUnread += 1;
      }
    });

    setUnread(newUnread);
  }
  useEffect(() => {
    setUnread(0);
  }, []);
  useEffect(() => {
    if (!loading) {
      parseMessages(
        snapshot.docs.map((doc) => ({ id: doc.id, message: doc.data() }))
      );
    }
  }, [snapshot, loading, numberPerChat]);
  function getYPosition() {
    if (notificationsRef.current) {
      if (isAppBarShowing && position.y) {
        return (
          position.y - notificationsRef.current.getBoundingClientRect().height
        );
      }
    }
    return position?.y ?? 0;
  }

  function makeChatPillVisible(id) {
    var newVisibleMiniChats = [...visibleMiniChats];
    if (!newVisibleMiniChats.includes(id)) {
      newVisibleMiniChats.push(id);
    }
    setVisibleMiniChats(newVisibleMiniChats);
    setForceShowChat(id);

    setShow(false);
  }

  function makeChatPillInvisible(id) {
    var newVisibleMiniChats = visibleMiniChats.filter((obj) => obj != id);
    setVisibleMiniChats(newVisibleMiniChats);
  }
  return (
    <>
      <MiniChatContainer
        chats={chats}
        visibleChats={visibleMiniChats}
        makeChatInvisible={makeChatPillInvisible}
        onClick={() => setShow(false)}
        numberPerChat={numberPerChat}
        forceShowChatId={forceShowChat}
      />
      <UserNotificationsWrapper
        show={show}
        positionX={position?.x ?? 0}
        positionY={getYPosition()}
        ref={notificationsRef}
      >
        <div className="title">Chat Groups </div>
        {(!chats || chats.length == 0) && (
          <ChatNotification>No chat yet. </ChatNotification>
        )}
        {chats &&
          chats.map((obj, i) => (
            <ChatNotification
              key={i}
              onClick={() => makeChatPillVisible(obj.id)}
            >
              {numberPerChat[obj.id] > 0 && (
                <div className="unread">{numberPerChat[obj.id]}</div>
              )}
              <div className="title">{obj.chat && obj.chat.title}</div>
            </ChatNotification>
          ))}
      </UserNotificationsWrapper>
    </>
  );
}

const ChatNotification = styled(UserNotification)`
  .title {
    font-size: 1rem;
  }
  .unread {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: var(--white-text);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 1rem;
  }
`;

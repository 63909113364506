import {
  runTutorial,
  setStepIndex,
  stopTour,
  stopTutorial,
} from "../redux/tutorial";

export const freeTestTutorialCallback = (
  data,
  navigate,
  dispatch,
  onEndCallback,
  courseId
) => {
  const { action, index, lifecycle, type } = data;
  console.log(data);

  if (type === "step:after") {
    if (index >= 1 && action === "prev") {
      //dispatch(runTutorial());
      dispatch(setStepIndex(index - 1));
    } else if (action === "next") {
      //dispatch(runTutorial());
      dispatch(setStepIndex(index + 1));
    }
  }
  if (index == 5 && lifecycle === "complete") {
    onEndCallback();
    dispatch(stopTutorial());
    dispatch(setStepIndex(0));
    dispatch(stopTour());
  }
  if (action == "close") {
    onEndCallback();
    dispatch(stopTutorial());
    dispatch(setStepIndex(0));
    dispatch(stopTour());
  }
};
export const freeTestTutorialSteps = [
  {
    target: ".tutorial1",
    content: (
      <>
        <p>
          Since you have subscribed to the free course, your access will be
          limited. After you subscribe, you will have access to:
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: ".tutorial2BoostYourEnglish",
    content: (
      <>
        <p>Boost Your English trail, where you can improve your English.</p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: ".tutorial2TalkingAboutScore",
    content: (
      <>
        <p>
          Talking about Score trail, where you will prepare for the necessary
          tests in the application process.
        </p>
      </>
    ),
  },
  {
    target: "#tutorMeetings",
    content: (
      <>
        <p> Individual tutoring with an expert.</p>
      </>
    ),
  },
  {
    target: ".tutorial2YourWayToCollege",
    content: (
      <>
        <p>
          FULL Your Way to College Application path.For now, you will have
          access to a portion of the Your Way to College Application course and
          you can see your progress here.
        </p>
        <p>
          Also, you will be able to access our chat to exchange experiences with
          those who are going through the same process as you!
        </p>
      </>
    ),
  },
  {
    target: "#buttonFeedback",
    content: <>If you have any questions, send them to us here:</>,
  },
];

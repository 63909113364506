import React, { createContext } from 'react';

const TestTimerContext = createContext([
  {
    testId: "",
    isRunning: false,
    currentTimer: 0,
    duration: 0,
    studentId: "",
    isTimerRunningOut: false,
  },
  (value, onUpdateStoreCallback, onTimerFinishCallback) => ({
    testId: "",
    isRunning: false,
    currentTimer: 0,
    duration: 0,
    studentId: "",
    isTimerRunningOut: false,
  }),
]);

export default TestTimerContext;

export const SET_CONTENT_LIST = "app/content_view/set_content_list";
export const SET_TEST_CONTENT_LIST = "app/content_view/set_test_content_list";
export const SET_CONTENT_SECTION_LIST =
  "app/content_view/set_content_section_list";
export const SET_TEST_CONTENT_SECTION_LIST =
  "app/content_view/set_test_content_section_list";
export const SET_IS_TEST_RELATIVE_WEIGHTS =
  "app/content_view/set_is_test_relative_weights";

export const SET_CURRENT_SECTION_TITLE =
  "app/content_view/set_current_section_title";
export const SET_TEST_WEIGHTS = "app/content_view/set_test_weights";
export const SET_CURRENT_SECTION = "app/content_view/current_section";
export const SET_CURRENT_CONTENT = "app/content_view/set_current_content";
export const SET_CURRENT_CONTENT_TYPE =
  "app/content_view/set_current_content_type";
export const SET_NEXT_CONTENT = "app/content_view/set_next_content";
export const COURSE_FINISHED = "app/content_view/course_finished";

const RESET_STATE = "app/reset_state";

const initialState = {
  contentList: {},
  testContentList: {},
  currentContent: 0,
  currentSection: 0,
  currentSectionTitle: "",
  currentContentType: "",
  contentSectionList: {},
  testContentSectionList: {},
  isTestRelativeWeights: {},
  testWeights: {},
};

export default function contentViewReducer(state = initialState, action) {
  let newState = null;
  switch (action.type) {
    case SET_CURRENT_CONTENT:
      newState = { ...state };
      newState.currentContent = action.currentContent;
      return newState;
    case SET_CURRENT_SECTION:
      newState = { ...state };
      newState.currentSection = action.currentSection;
      return newState;
    case SET_CURRENT_CONTENT_TYPE:
      newState = { ...state };
      newState.currentContentType = action.currentContentType;
      return newState;
    case SET_NEXT_CONTENT:
      newState = { ...state };

      if (state.currentContent === 0) {
        newState.currentContent = state.contentList[0];
      } else {
        let currentIndex = state.contentList.findIndex(
          (obj) => obj === action.currentContent
        );
        if (currentIndex + 1 === state.contentList.length) {
          newState.currentContent = COURSE_FINISHED;
        } else {
          newState.currentContent = state.contentList[currentIndex + 1];
        }
      }
      return newState;

    case SET_CONTENT_LIST:
      newState = { ...state };
      newState.contentList = action.contentList;
      return newState;
    case SET_TEST_WEIGHTS:
      newState = { ...state };
      newState.testWeights = action.testWeights;
      return newState;
    case SET_IS_TEST_RELATIVE_WEIGHTS:
      newState = { ...state };
      newState.isTestRelativeWeights = action.isTestRelativeWeights;
      return newState;
    case SET_TEST_CONTENT_LIST:
      newState = { ...state };
      newState.testContentList = action.testContentList;
      return newState;
    case SET_CONTENT_SECTION_LIST:
      newState = { ...state };
      newState.contentSectionList = action.contentSectionList;
      return newState;
    case SET_TEST_CONTENT_SECTION_LIST:
      newState = { ...state };
      newState.testContentSectionList = action.testContentSectionList;
      return newState;
    case SET_CURRENT_SECTION_TITLE:
      newState = { ...state };
      newState.currentSectionTitle = action.sectionTitle;
      return newState;
    case RESET_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
}
export const setTestContentSectionList = (testContentSectionList) => ({
  type: SET_TEST_CONTENT_SECTION_LIST,
  testContentSectionList: testContentSectionList,
});

export const setContentSectionList = (contentSectionList) => ({
  type: SET_CONTENT_SECTION_LIST,
  contentSectionList: contentSectionList,
});

export const setContentList = (contentList) => ({
  type: SET_CONTENT_LIST,
  contentList: contentList,
});

export const setCurrentSectionTitle = (sectionTitle) => ({
  type: SET_CURRENT_SECTION_TITLE,
  sectionTitle: sectionTitle,
});
export const setIsTestRelativeWeights = (isTestRelativeWeights) => ({
  type: SET_IS_TEST_RELATIVE_WEIGHTS,
  isTestRelativeWeights: isTestRelativeWeights,
});
export const setTestWeights = (testWeights) => ({
  type: SET_TEST_WEIGHTS,
  testWeights: testWeights,
});
export const setTestContentList = (testContentList) => ({
  type: SET_TEST_CONTENT_LIST,
  testContentList: testContentList,
});
export const setCurrentContent = (currentContent) => ({
  type: SET_CURRENT_CONTENT,
  currentContent: currentContent,
});
export const setCurrentContentType = (currentContentType) => ({
  type: SET_CURRENT_CONTENT_TYPE,
  currentContentType: currentContentType,
});

export const setCurrentSection = (currentSection) => ({
  type: SET_CURRENT_SECTION,
  currentSection: currentSection,
});

export const setNextContent = () => ({
  type: SET_NEXT_CONTENT,
});

const SET_PROFILE = "app/profile/SET_PROFILE";
const SET_PROFILE_HAS_FREE_PLAN = "app/profile/SET_PROFILE_HAS_FREE_PLAN ";
const SET_PROFILE_HAS_PAID_PLAN = "app/profile/SET_PROFILE_HAS_PAID_PLAN";
const RESET_STATE = "app/reset_state";

const initialState = {
  firstName: "STUDENT",
  lastName: "",
  gender: "",
  cpf: "",
  cellphone: "",
  birthdate: "",
  role: "STUDENT",
  address: {
    zipcode: "",
    address: "",
    number: "",
    complement: "",
    city: "",
    state: "",
    country: "",
    district: "",
  },
  status: "DEFAULT",
  registeredOn: "",
  profilePicture: "",
  hasTutorialFreePlan: false,
  hasTutorialPaidPlan: false,
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return { ...action.profile };
    case RESET_STATE:
      return { ...initialState };
    case SET_PROFILE_HAS_FREE_PLAN:
      return { ...state, hasTutorialFreePlan: action.value };
    case SET_PROFILE_HAS_PAID_PLAN:
      return { ...state, hasTutorialPaidPlan: action.value };
    default:
      return { ...state };
  }
}

export const setProfile = (profile) => ({
  type: SET_PROFILE,
  profile: profile,
});

export const setHasTutorialFreePlan = (value) => ({
  type: SET_PROFILE_HAS_FREE_PLAN,
  value: value,
});

export const setHasTutorialPaidPlan = (value) => ({
  type: SET_PROFILE_HAS_PAID_PLAN,
  value: value,
});

export const RUN_TOUR = "app/run_tour";
export const STOP_TOUR = "app/stop_tour";
export const RUN_TUTORIAL = "app/run_tutorial";
export const STOP_TUTORIAL = "app/stop_tutorial";
export const SET_STEP_INDEX = "app/set_step_index";
export const SET_COURSE_ID = "app/set_step_course_id";
const RESET_STATE = "app/reset_state";
const initialState = {
  run: false,
  stepIndex: 0,
  tourActive: false,
  courseId: "",
  id: "",
};
export default function tutorialReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return { ...initialState };
    case RUN_TUTORIAL:
      return { ...state, run: true, id: action.id };
    case STOP_TUTORIAL:
      return { ...state, run: false };
    case RUN_TOUR:
      return { ...state, tourActive: true };
    case STOP_TOUR:
      return { ...state, tourActive: false };
    case SET_COURSE_ID:
      return { ...state, courseId: action.courseId };
    case SET_STEP_INDEX:
      return { ...state, stepIndex: action.stepIndex };
    default:
      return state;
  }
}

export const runTutorial = (id = "") => ({
  type: RUN_TUTORIAL,
  id: id,
});

export const stopTutorial = () => ({
  type: STOP_TUTORIAL,
});

export const runTour = () => ({
  type: RUN_TOUR,
});

export const stopTour = () => ({
  type: STOP_TOUR,
});
export const setStepIndex = (step) => ({
  type: SET_STEP_INDEX,
  stepIndex: step,
});
export const setTutorialCourseId = (courseId) => ({
  type: SET_COURSE_ID,
  courseId: courseId,
});

import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { nanoid } from "nanoid";

const db = collection(firebase, "/chat");

class ChatDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, chat: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/chat", id));
    return snapshot;
  }

  async getFromUserId(id) {
    const q = await getDocs(
      query(collection(firebase, "/chat"), where("users", "array-contains", id))
    );

    var result = q.docs
      .map((doc) => ({ id: doc.id, chat: doc.data() }))
      .filter((obj) => !obj.chat.isDeleted);
    return result;
  }

  async create(id, data) {
    const docRef = await setDoc(doc(firebase, "chat", id), data);
    return docRef;
  }

  async update(id, data) {
    await updateDoc(doc(firebase, "/chat", id), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/chat", id));
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new ChatDataService();

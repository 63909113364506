import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";

import Loading from "../components/Loading";
import { auth } from "../firebase";
import UserProfileDataService from "../services/userProfile";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmailAddress(email) {
    return updateEmail(currentUser, email);
  }

  function sendVerification(user) {
    if (user) {
      return sendEmailVerification(user);
    }
    return sendEmailVerification(currentUser);
  }
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function getRole(uid) {
    if (uid) {
      return UserProfileDataService.getRole(uid);
    }
    if (currentUser) {
      return UserProfileDataService.getRole(currentUser.uid);
    }
  }
  function getProfileInfo() {
    if (currentUser) {
      return UserProfileDataService.get(currentUser.uid);
    }
    return { exists: () => false };
  }

  useEffect(() => {
    const unsubcribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubcribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmailAddress,
    updatePassword,
    getRole,
    sendVerification,
    getProfileInfo,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading && <Loading />}
      {!loading && children}
    </AuthContext.Provider>
  );
}

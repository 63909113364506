import React, { useEffect, useState } from "react";
import QuizDataService from "../../services/quiz";
import UserProfileDataService from "../../services/userProfile";
import { ROLE_STUDENT } from "../../consts";
export default function ExtractIntroQuiz() {
  const [data, setData] = useState({});
  const empty_introquiz = {
    applicationKnowledgeSelect: "",
    englishProfSelect: "",
    bilingualSchoolSelect: "",
    hasEnglishProfSelect: "",
    englishTestDict: "",
    applicationGoal: "",
    applicationTestDict: "",
    applicationObjective: "",
    hasApplied: "No",
    hasBeenAccepted: "No",
  };
  const notPrint = [
    "lastUpdate",
    "role",
    "registeredOn",
    "profilePicture",
    "CIALFOID",
    "numberMeetings",
    "linkedIn",
    "profTestCheckbox",
    "hasTutorialFreePlan",
    "applicationTarget",
    "goalOtherText",
    "hasApplicationList",
    "goalSelect",
    "applicationStatus",
    "applicationInstitution",
    "applicationKnowledge",
    "hasApplication",
    "hasDiagnostic",
    "subscriptionTitle",
    "hasTutorial",
  ];
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await UserProfileDataService.getAllFromRole(
          ROLE_STUDENT
        );
        const responseQuiz = await QuizDataService.getAll(ROLE_STUDENT);
        console.log(response, responseQuiz);
        console.log(response);
        var set = new Set();
        let mix = response.map((profile) => {
          const idx = responseQuiz.findIndex((obj) => obj.id == profile.id);
          let introquiz = empty_introquiz;
          if (idx != -1) {
            introquiz = responseQuiz[idx].content.introQuiz;
            if (
              introquiz.englishTestDict &&
              introquiz.englishTestDict.length > 0
            ) {
              introquiz.englishTestDict = introquiz.englishTestDict
                .map((test) => test.test[0] + " - " + test.grade + " | ")
                .flat();
            } else {
              introquiz.englishTestDict = "";
            }
            if (
              introquiz.applicationTestDict &&
              introquiz.applicationTestDict.length > 0
            ) {
              introquiz.applicationTestDict = introquiz.applicationTestDict
                .map((test) => test.test[0] + " - " + test.grade + " | ")
                .flat();
            } else {
              introquiz.applicationTestDict = "";
            }
          }
          const result = {
            id: profile.id,
            ...profile.user,
            ...profile.user.address,
            ...introquiz,
          };

          Object.keys(result).map((k) => set.add(k));
          return result;
        });
        console.log(mix, set);
        var csv = "data:text/csv;charset=utf-8,";
        Array.from(set).map((key) => {
          if (notPrint.includes(key)) {
            return;
          }
          csv += key + ";";
        });
        csv += "\r\n";
        mix.map((obj) => {
          Array.from(set).map((key) => {
            if (notPrint.includes(key)) {
              return;
            }
            console.log(key, obj[key], key in obj ? obj[key] : "" + ";");
            csv += (key in obj ? obj[key] : "") + ";";
          });
          csv += "\r\n";
        });
        var encodedUri = encodeURI(csv);
        window.open(encodedUri);
        console.log(csv);
      } catch (err) {
        console.error(err);
      }
    };
    getUsers();
  });

  return <div>ExtractIntroQuiz</div>;
}

import styled from "styled-components";
import { Button } from "react-bootstrap";

const ButtonStyled = styled(Button)`
  align-content: center;
  background-color: var(--fourteenthcolor);
  border: none;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: auto;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  :hover {
    background-color: var(--fourteenthcolor);
  }
`;
export default ButtonStyled;

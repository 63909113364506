export const STATUS_PROFILE_CREATED = "PROFILE_CREATED";
export const STATUS_FIRST_PAYMENT_PENDING = "FIRST_PAYMENT_PENDING";
export const STATUS_PAYMENT_CANCELLED = "PAYMENT_CANCELLED";
export const STATUS_PAYMENT_PENDING = "PAYMENT_PENDING";
export const STATUS_ACTIVE = "ACTIVE";
export const NOTIFICATION_UPDATE_STUDENT_ACTIVITY = "UPDATE_STUDENT_ACTIVITY";
export const NOTIFICATION_SIGNUP = "SIGNUP";
export const NOTIFICATION_CREATE_PROFILE = "PROFILE_CREATED";
export const NOTIFICATION_ACTIVITY_PENDING_CORRECTION =
  "ACTIVITY_PENDING_CORRECTION";
export const NOTIFICATION_VIDEO_WATCHED = "VIDEO_WATCHED";
export const NOTIFICATION_TUTOR_CORRECTED = "TUTOR_CORRECTED";
export const NOTIFICATION_NEW_SUBSCRIPTION = "NEW_SUBSCRIPTION";
export const NOTIFICATION_PAYMENT_UNKOWN_ERROR = "PAYMENT_UNKOWN_ERROR";
export const NOTIFICATION_CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const NOTIFICATION_NEW_CANCELLATION = "NEW_CANCELLATION";
export const NOTIFICATION_NEW_DIAGNOSTIC = "NEW_DIAGNOSTIC";
export const NOTIFICATION_LOGIN = "NEW_LOGIN";
export const NOTIFICATION_VIEW_HOMEPAGE = "NOTIFICATION_VIEW_HOMEPAGE";
export const NOTIFICATION_PAGEVIEW = "NOTIFICATION_PAGEVIEW";
export const NOTIFICATION_NEW_MEETING = "NOTIFICATION_NEW_MEETING";
export const NOTIFICATION_MEETING_CANCELLED = "NOTIFICATION_MEETING_CANCELLED";
export const NOTIFICATION_WELCOME = "NOTIFICATION_WELCOME";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const ROLE_TUTOR = "TUTOR";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_SUPER_ADMIN = "SUPER_ADMIN";
export const ROLE_STUDENT = "STUDENT";
export const ROLE_NO_ROLE = "NO_ROLE";
export const UID_SYSTEM_NOTIFICATION = "system";
export const ACHIEVEMENT_COND_NUMBER_TEST = "NUMBER_OF_TESTS";
export const ACHIEVEMENT_COND_NUMBER_SECTION = "NUMBER_OF_SECTION";
export const ACHIEVEMENT_COND_NUMBER_EXERCISE = "NUMBER_OF_EXERCISE";
export const ACHIEVEMENT_COND_TIME_ON_PLATFORM = "TIME_ON_PLATFORM";
export const CHAT_TOO_MANY_FLAGS_NUMBER = 3;
export const CHAT_TOO_MANY_FLAGS =
  "[Message is under review after many users flagged it.]";
export const CHAT_DELETED_MESSAGE = "[Deleted message.]";
export const CHAT_BLOCKED_ROUTES = [{ path: "/c/:id/v/:id2" }];
export const TEST_TIMER_ROUTES = [
  { path: "/c/:id/t/:id2" },
  { path: "/diagnostic" },
];
export const PRODUCT_CONFIG_DOCUMENT_ID = "productConfig";
export const DEFAULT_NUMBER_OF_MEETINGS_PER_MONTH = 3;
export const CONFIG_MEETING_NUMBER_FIELD = "meetingNumber";
export const CONFIG_DIAGNOSTIC_TEST = "diagnosticTest";
export const CONFIG_STUDENT_GROUP_TYPE = "studentGroupType";
export const EXERCISE_TYPE_ALBUM = "album";
export const EXERCISE_TYPE_VIDEO = "video";
export const EXERCISE_TYPE_OPEN_QUESTION = "openQuestion";
export const EXERCISE_TYPE_MULTIPLE_CHOICE = "multipleChoice";
export const EXERCISE_TYPE_GROUP = "group";
export const EXERCISE_TYPE_FORM = "form";
export const PAYMENT_TYPE_CREDIT_CARD = "creditCard";
export const PAYMENT_TYPE_BOLETO = "boleto";
export const PAYMENT_TYPE_MERCADO_PAGO = "mercadoPago";
export const TEST_TIMER_SAVE_EVERY_SECONDS = 60;
export const DIAGNOSTIC_TEST_TIMER_DURATION = 900;
export const NOTIFICATION_NEW_TEST = "NEW_TEST";
export const SECTION_TYPE_GRAMMAR = "GRAMMAR";
export const SECTION_TYPE_VOCABULARY = "VOCABULARY";
export const SECTION_TYPE_CRIT_READING = "CRITICAL_READING";
export const SECTION_COLOR_ALL = "#c0aa81";
export const SECTION_COLOR_GRAMMAR = "#6ba1cb";
export const SECTION_COLOR_VOCABULARY = "#613372";
export const SECTION_COLOR_CRIT_READING = "#e47233";
export const CUPON_FREE_TEST = "HOPON-AGOSTO23";
export const MAX_STARS = 3;
export const SUBSCRIPTION_TYPE_TRIAL = "TRIAL";
export const SUBSCRIPTION_TYPE_ANNUAL = "ANNUAL";
export const SUBSCRIPTION_TYPE_TRIMESTER = "TRIMESTER";
export const SUBSCRIPTION_TYPE_SEMESTER = "SEMESTER";
export const FREE_TEST_PRODUCT_ID = "FREE_TEST";

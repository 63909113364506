import React from 'react';
import { FaFontAwesomeFlag } from 'react-icons/fa';
import styled from 'styled-components';

import EmptyProfilePicture from '../../assets/images/emptyprofile.png';

export default function ChatMessage({
  obj,
  messages,
  i,
  onShowModalFlag,
  isMiniChat,
}) {
  function parseTimestamp(timestamp) {
    return (
      timestamp.hour.toString().padStart(2, "0") +
      ":" +
      timestamp.minute.toString().padStart(2, "0")
    );
  }
  return (
    <ChatMessageContainer
      outcoming={obj.outcoming}
      isStudent={obj.isStudent}
      isMiniChat={isMiniChat}
    >
      <div className="chatMessageUser">
        {obj.name != messages[i + 1]?.name && (
          <img
            src={
              obj.authorProfilePicture == ""
                ? EmptyProfilePicture
                : obj.authorProfilePicture
            }
            className={
              obj.isStudent ? "img-profile-small" : "img-profile-small tutor"
            }
          />
        )}
        {obj.name == messages[i + 1]?.name && <div className="spacer"></div>}
      </div>
      <div>
        {(i == 0 || (i > 0 && obj.name != messages[i - 1].name)) && (
          <div className="chatMessageUserName">{obj.name}</div>
        )}
        <div className="chatMessage">{obj.message}</div>
      </div>
      <div className="timestamp">{parseTimestamp(obj.timestamp)}</div>
      <div className="flag" onClick={() => onShowModalFlag(obj.id)}>
        <FaFontAwesomeFlag />
      </div>
    </ChatMessageContainer>
  );
}

const ChatMessageContainer = styled.div`
  justify-content: ${(props) => (props.outcoming ? "flex-end" : "flex-start")};
  display: flex;
  align-items: center;
  margin: 0.2rem 0rem;
  .spacer {
    width: 66px;
  }
  min-height: 66px;
  .chatMessageUser,
  .timestamp {
    align-self: self-end;
    font-size: 0.7rem;
  }
  .chatMessageUser {
    order: ${(props) => (props.outcoming ? 5 : 0)};
    display: ${(props) => (props.isMiniChat ? "none" : "block")};
  }
  .chatMessageUserName {
    margin: 0.4rem;
  }
  .chatMessage {
    padding: 0.6rem 0.7rem;
    border-radius: 2rem;
    background-color: ${(props) =>
      props.outcoming
        ? "var(--accent)"
        : props.isStudent
        ? "var(--sevencolor)"
        : "var(--fourteenthcolor)"};
    color: var(--white-text);
    margin-bottom: 0.5rem;
  }
  .flag:hover {
    color: darkred;
  }
  .flag {
    opacity: 0;
    font-size: 1.5rem;
    order: ${(props) => (props.outcoming ? -1 : 5)};
    margin: 0 1rem;
  }
`;

import React from 'react';
import { BsFillCalendarWeekFill } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { GiCheckMark } from 'react-icons/gi';
import { GoHome } from 'react-icons/go';
import { MdEventAvailable } from 'react-icons/md';
import { MenuItem } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export default function TutorSidebar() {
  const navigate = useNavigate();
  return (
    <>
      <ReactTooltip />
      <MenuItem
        data-tip="Home"
        onClick={() => navigate("home")}
        icon={<GoHome />}
      >
        <Link to="home">Home</Link>
      </MenuItem>
      <MenuItem
        data-tip="Update Profile"
        onClick={() => navigate("/updateprofile")}
        icon={<CgProfile />}
      >
        <Link to="/updateprofile">Profile</Link>
      </MenuItem>
      <hr />
      <MenuItem
        data-tip="Correct Questions"
        onClick={() => navigate("/givemark")}
        icon={<GiCheckMark />}
      >
        <Link to="/givemark">Correct Questions</Link>
      </MenuItem>
      <MenuItem
        data-tip="Tutor Meetings"
        onClick={() => navigate("/viewmeetings")}
        icon={<BsFillCalendarWeekFill />}
      >
        <Link to="/viewmeetings">Tutor Meetings</Link>
      </MenuItem>
      <MenuItem
        data-tip="Meeting Availability"
        onClick={() => navigate("/setavailability")}
        icon={<MdEventAvailable />}
      >
        <Link to="/setavailability">Meeting Availability</Link>
      </MenuItem>
      <MenuItem
        data-tip="Sign Out"
        onClick={() => navigate("/logout")}
        icon={<FiLogOut />}
      >
        <Link to="/logout"> Sign Out</Link>
      </MenuItem>
    </>
  );
}

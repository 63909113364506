import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { nanoid } from "nanoid";

const db = collection(firebase, "/userFeedback");

class UserFeedbackDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, content: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/userFeedback", id));
    return snapshot;
  }

  async create(data) {
    const docRef = await setDoc(doc(firebase, "userFeedback", nanoid()), data);
    return docRef;
  }

  async update(id, data) {
    await updateDoc(doc(firebase, "/userFeedback", id), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/userFeedback", id));
  }
  async hasFeedbackContent(userId, activityId) {
    const q = await getDocs(
      query(
        collection(firebase, "/userFeedback"),
        where("userId", "==", userId),
        where("content", "==", activityId)
      )
    );
    return q.docs.length > 0;
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new UserFeedbackDataService();

import React, { useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

import { TEST_TIMER_ROUTES, TEST_TIMER_SAVE_EVERY_SECONDS } from "../consts";
import { parseTimeSpent } from "../util";
import TestTimerContext from "./Student/TestTimerContext";
import TestTimer from "./TestTimer";

export default function AppContextProvider({ children }) {
  const [currentTimeouts, setCurrentTimeouts] = useState([]);
  const [currentTimer, setTimer] = useState({});

  const currentPath = useLocation();

  function setCurrentTimer(
    value,
    onUpdateStoreCallback,
    onTimerFinishCallback
  ) {
    for (var i = 0; i < currentTimeouts.length; i++) {
      clearTimeout(currentTimeouts[i]);
    }
    setCurrentTimeouts([]);
    const route = matchRoutes(TEST_TIMER_ROUTES, currentPath);

    var newValue = {
      ...value,
      text: parseTimeSpent(value.duration - value.currentTimer),
    };

    if (
      value.currentTimer % TEST_TIMER_SAVE_EVERY_SECONDS == 0 &&
      onUpdateStoreCallback
    ) {
      console.log(currentTimer);
      onUpdateStoreCallback(value);
    }

    if (
      value.duration - value.currentTimer <= 0 &&
      onUpdateStoreCallback &&
      onTimerFinishCallback
    ) {
      newValue = { ...newValue, isRunning: false };
      setTimer(newValue);
      onUpdateStoreCallback(newValue);

      onTimerFinishCallback(newValue);
      return;
    }
    setTimer(newValue);

    if (route) {
      console.log("SET ", value);
      if (value.isRunning && value.currentTimer != value.duration) {
        const text = parseTimeSpent(value.duration - (value.currentTimer + 1));
        const newValue = {
          ...value,
          currentTimer: value.currentTimer + 1,
          text: text,
          isTimerRunningOut:
            value.duration - value.currentTimer < 0.15 * value.duration ||
            value.duration - value.currentTimer < 30,
        };
        var newTimeouts = [...currentTimeouts];
        if (currentTimeouts.length == 0) {
          const timeout = setTimeout(
            () =>
              setCurrentTimer(
                newValue,
                onUpdateStoreCallback,
                onTimerFinishCallback
              ),
            1000
          );
          newTimeouts.push(timeout);
        }
        setCurrentTimeouts(newTimeouts);
      }
      setTimer(value);
    } else {
      console.log("saiu");
    }
  }
  return (
    <TestTimerContext.Provider value={[currentTimer, setCurrentTimer]}>
      <TestTimer />
      {children}
    </TestTimerContext.Provider>
  );
}

import { SECTION_TYPE_GRAMMAR } from "../consts";
import { resetState } from "../store";

const SET_COURSE = "app/course/set_course";
const SET_COURSE_INFO = "app/course/set_info";
const SET_SECTION_INFO = "app/course/set_section_info";
const SET_CONTENT_INFO = "app/course/set_content_info";
const SET_SECTION_GROUP = "app/course/set_section_group";
const SET_SECTION_GROUPS = "app/course/set_section_groups";
const SET_SECTION_CONTENT = "app/course/set_section_content";
const SET_SECTION_TEST = "app/course/set_section_test";
const SET_SECTION_GROUP_TEST = "app/course/set_section_group_test";
const CHANGE_SECTION_ORDER_UP = "app/course/change_section_order_up";
const CHANGE_SECTION_ORDER_DOWN = "app/course/change_section_order_down";
const CHANGE_CONTENT_ORDER_UP = "app/course/change_content_order_up";
const CHANGE_CONTENT_ORDER_DOWN = "app/course/change_content_order_down";
const CHANGE_CONTENT_SECTION = "app/course/change_content_section";

const ADD_SECTION = "app/course/add_section";
const ADD_CONTENT = "app/course/add_content";

const REMOVE_CONTENT = "app/course/remove_content";
const REMOVE_SECTION = "app/course/remove_section";

const RESET_STATE = "app/reset_state";
const SWITCH_SECTIONS = "app/switch_sections";
const REMOVE_SECTION_GROUP_TEST_CONTENT =
  "app/course/remove_section_group_test_content";

const initialSectionState = [
  {
    title: "First Section",
    description: "",
    content: [],
    hasTest: false,
    test: { content: [], weights: [] },
    sectionGroup: -1,
    sectionType: SECTION_TYPE_GRAMMAR,
  },
];

const initialState = {
  id: "NO_ID",
  title: "Course Title",
  description: "",
  sections: initialSectionState,
  sectionGroups: [
    {
      title: "Group 1",
      description: "",
      hasTest: false,
      test: { content: [], weights: [] },
    },
  ],
};

export function sectionReducer(state = initialSectionState, action) {
  let temp = null;
  let contentList = null;
  let sections = null;
  let section = null;
  let idx = null;
  switch (action.type) {
    case ADD_SECTION:
      return [
        ...state,
        {
          title: action.title,
          description: action.description,
          content: [],
          hasTest: action.hasTest ?? false,
          test: { content: [], weights: [] },
          sectionGroup: -1,
        },
      ];

    case SET_SECTION_GROUP:
      sections = [...state];
      sections[action.sectionIndex].sectionGroup = action.sectionGroup;
      return sections;
    case SET_SECTION_INFO:
      sections = [...state];
      sections[action.sectionIndex].title = action.title;
      sections[action.sectionIndex].description = action.description;
      sections[action.sectionIndex].hasTest = action.hasTest ?? false;

      return sections;
    case CHANGE_SECTION_ORDER_UP:
      sections = [...state];
      temp = sections[action.index];
      sections[action.index] = sections[action.index - 1];
      sections[action.index - 1] = temp;
      return sections;
    case CHANGE_SECTION_ORDER_DOWN:
      sections = [...state];
      temp = sections[action.index];
      sections[action.index] = sections[action.index + 1];
      sections[action.index + 1] = temp;
      return sections;

    case SWITCH_SECTIONS:
      sections = [...state];
      temp = sections[action.index1];
      sections[action.index1] = sections[action.index2];
      sections[action.index2] = temp;
      return sections;

    case ADD_CONTENT:
      sections = [...state];
      contentList = [...sections[action.sectionIndex].content];
      contentList = [
        ...contentList,
        { id: action.id, description: action.description },
      ];
      sections[action.sectionIndex].content = contentList;
      return sections;
    case REMOVE_CONTENT:
      sections = [...state];
      if (action.fromTest) {
        console.log("aaa");
        contentList = [...sections[action.sectionIndex].test.content];
        contentList = contentList.filter((value) => value.id !== action.id);
        sections[action.sectionIndex].test.content = contentList;
      } else {
        contentList = [...sections[action.sectionIndex].content];
        contentList = contentList.filter((value) => value.id !== action.id);
        sections[action.sectionIndex].content = contentList;
      }

      return sections;
    case CHANGE_CONTENT_ORDER_UP:
      section = [...state[action.sectionIndex].content];
      idx = section.findIndex((obj) => obj.id === action.contentIndex);
      temp = section[idx];
      section[idx] = section[idx - 1];
      section[idx - 1] = temp;
      sections = [...state];
      sections[action.sectionIndex].content = section;
      return sections;
    case CHANGE_CONTENT_ORDER_DOWN:
      section = [...state[action.sectionIndex].content];
      idx = section.findIndex((obj) => obj.id === action.contentIndex);
      temp = section[idx];
      section[idx] = section[idx + 1];
      section[idx + 1] = temp;
      sections = [...state];
      sections[action.sectionIndex].content = section;
      return sections;
    case CHANGE_CONTENT_SECTION:
      if (action.to === action.from) {
        return state;
      }
      section = [...state[action.from].content];
      idx = section.findIndex((obj) => obj.id === action.contentIndex);
      let content = { ...section[idx] };
      section.splice(idx, 1);
      sections = [...state];
      sections[action.to].content = [...sections[action.to].content, content];
      sections[action.from].content = section;
      return sections;
    case SET_CONTENT_INFO:
      sections = [...state];
      sections[action.sectionIndex].content[action.contentIndex].description =
        action.description;
      return sections;

    case SET_SECTION_CONTENT:
      sections = [...state];
      sections[action.sectionIndex].content = action.content;
      return sections;
    case SET_SECTION_TEST:
      sections = [...state];
      sections[action.sectionIndex].test = action.test;
      return sections;
    case RESET_STATE:
      return [...initialSectionState];
    default:
      return [...state];
  }
}

export default function courseReducer(state = initialState, action) {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case SET_COURSE:
      return { ...action.course };

    case SET_COURSE_INFO:
      return { ...state, title: action.title, description: action.description };
    case ADD_SECTION:
    case SET_SECTION_INFO:
    case SET_CONTENT_INFO:
    case SET_SECTION_GROUP:
    case ADD_CONTENT:
    case REMOVE_CONTENT:
    case CHANGE_SECTION_ORDER_UP:
    case CHANGE_SECTION_ORDER_DOWN:
    case CHANGE_CONTENT_ORDER_UP:
    case CHANGE_CONTENT_ORDER_DOWN:
    case CHANGE_CONTENT_SECTION:
    case SET_SECTION_CONTENT:
    case SET_SECTION_TEST:
      return { ...state, sections: sectionReducer(state.sections, action) };

    case SET_SECTION_GROUPS:
      return {
        ...state,

        sectionGroups: action.sectionGroups,
      };
    case SET_SECTION_GROUP_TEST:
      console.log(action.sectionGroupIndex, action.test);
      if (newState.sectionGroups[action.sectionGroupIndex]) {
        newState.sectionGroups[action.sectionGroupIndex].test = action.test;
      }
      return newState;

    case REMOVE_SECTION_GROUP_TEST_CONTENT:
      let contentList = [
        ...newState.sectionGroups[action.sectionIndex].test.content,
      ];
      contentList = contentList.filter((value) => value.id !== action.id);
      newState.sectionGroups[action.sectionIndex].test.content = contentList;
      return newState;
    case REMOVE_SECTION:
      newState.sections = newState.sections.filter((obj, i) => i != action.id);
      return newState;

    case RESET_STATE:
      return { ...initialState };
    default:
      return { ...state, sections: sectionReducer(state.sections, action) };
  }
}

export const setCourse = (course) => ({
  type: SET_COURSE,
  course: course,
});

export const setCourseInfo = (title, description) => ({
  type: SET_COURSE_INFO,
  title: title,
  description: description,
});

export const setSectionInfo = (sectionIndex, title, description, hasTest) => ({
  type: SET_SECTION_INFO,
  sectionIndex: sectionIndex,
  title: title,
  description: description,
  hasTest: hasTest,
});
export const setSectionGroup = (sectionIndex, sectionGroup) => ({
  type: SET_SECTION_GROUP,
  sectionIndex: sectionIndex,
  sectionGroup: sectionGroup,
});
export const setSectionContent = (sectionIndex, content) => ({
  type: SET_SECTION_CONTENT,
  sectionIndex: sectionIndex,
  content: content,
});
export const setSectionTest = (sectionIndex, test) => ({
  type: SET_SECTION_TEST,
  sectionIndex: sectionIndex,
  test: test,
});
export const setSectionGroupTest = (sectionIndex, test) => ({
  type: SET_SECTION_GROUP_TEST,
  sectionGroupIndex: sectionIndex,
  test: test,
});
export const setSectionGroups = (sectionGroups) => ({
  type: SET_SECTION_GROUPS,

  sectionGroups: sectionGroups,
});

export const setContentInfo = (sectionIndex, contentIndex, description) => ({
  type: SET_CONTENT_INFO,
  sectionIndex: sectionIndex,
  contentIndex: contentIndex,
  description: description,
});
export const addSection = (title, description, hasTest) => ({
  type: ADD_SECTION,
  title: title,
  description: description,
  hasTest: hasTest ?? false,
});

export const changeSectionOrderUp = (index) => ({
  type: CHANGE_SECTION_ORDER_UP,
  index: index,
});

export const changeSectionOrderDown = (index) => ({
  type: CHANGE_SECTION_ORDER_DOWN,
  index: index,
});

export const switchSections = (index1, index2) => ({
  type: SWITCH_SECTIONS,
  index1: index1,
  index2: index2,
});

export const changeContentOrderUp = (sectionIndex, contentIndex) => ({
  type: CHANGE_CONTENT_ORDER_UP,
  sectionIndex: sectionIndex,
  contentIndex: contentIndex,
});

export const changeContentOrderDown = (sectionIndex, contentIndex) => ({
  type: CHANGE_CONTENT_ORDER_DOWN,
  sectionIndex: sectionIndex,
  contentIndex: contentIndex,
});

export const changeContentSection = (from, to, contentIndex) => ({
  type: CHANGE_CONTENT_SECTION,
  from: from,
  to: to,
  contentIndex: contentIndex,
});

export const addContent = (sectionIndex, id, description) => ({
  type: ADD_CONTENT,
  sectionIndex: sectionIndex,
  id: id,
  description: description,
});

export const removeContent = (sectionIndex, id, fromTest) => ({
  type: REMOVE_CONTENT,
  sectionIndex: sectionIndex,
  id: id,
  fromTest: fromTest,
});
export const removeSectionGroupTestContent = (sectionIndex, id) => ({
  type: REMOVE_SECTION_GROUP_TEST_CONTENT,
  sectionIndex: sectionIndex,
  id: id,
});

export const removeSection = (id) => ({
  type: REMOVE_SECTION,

  id: id,
});

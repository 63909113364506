import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/App.scss";

import React, { lazy, Suspense, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import AppWrapper from "./AppWrapper";
import Loading from "./Loading";

import LandingPage from "./LadingPage";
import "../i18n/config";
import IntentionQuiz from "./IntroQuiz/IntentionQuiz";
import ExtractIntroQuiz from "./IntroQuiz/ExtractIntroQuiz";
import Redirect from "./util/Redirect";

const NewSubscription = lazy(() => import("./Student/NewSubscription"));

const CIALFODashboard = lazy(() => import("./CIALFO/CIALFODashboard"));
const BuyMeeting = lazy(() => import("./Student/BuyMeeting"));
const Onboarding = lazy(() => import("./Onboarding/Onboarding"));
const Signup = lazy(() => import("./Signup"));
const Login = lazy(() => import("./Login"));
const PrivateWrapper = lazy(() => import("./PrivateWrapper.js"));
const CreateContent = lazy(() => import("./Content/CreateContent"));
const EditContent = lazy(() => import("./Content/EditContent"));
const CreateCourse = lazy(() => import("./Course/CreateCourse"));

const EditCourse = lazy(() => import("./Course/EditCourse"));
const CreateProduct = lazy(() => import("./Product/CreateProduct"));
const EditProduct = lazy(() => import("./Product/EditProduct"));

const ProductDashboard = lazy(() => import("./Admin/ProductDashboard"));
const StudentDashboard = lazy(() => import("./Home/StudentHome"));
const CreateStudentProfile = lazy(() =>
  import("./Onboarding/CreateStudentProfile")
);
const ViewCourseStudent = lazy(() => import("./Student/ViewCourseStudent"));
const Learn = lazy(() => import("./Student/Learn"));
const ReviewOpenQuestion = lazy(() => import("./Tutor/ReviewOpenQuestion"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const UpdateStudentProfile = lazy(() =>
  import("./Student/UpdateStudentProfile")
);
const Signout = lazy(() => import("./Signout"));
const LeadCaptureLanding = lazy(() => import("./LeadCaptureLanding"));
const EndCourse = lazy(() => import("./Student/EndCourse"));
const CourseReport = lazy(() => import("./Student/Results/CourseReport"));
const ViewContentActivity = lazy(() => import("./Product/ViewContentActivity"));
const DiagnosticTest = lazy(() => import("./DiagnosticTest/DiagnosticTest"));
const Error404 = lazy(() => import("./Error404"));
const ViewStudents = lazy(() => import("./Student/ViewStudents"));

const SectionReport = lazy(() => import("./Student/SectionReport"));
const StudentSelectProduct = lazy(() =>
  import("./Onboarding/StudentSelectProduct")
);
const FirstPayment = lazy(() => import("./Onboarding/FirstPayment"));
const PaymentInformation = lazy(() => import("./Student/PaymentInformation"));

const Welcome = lazy(() => import("./Onboarding/Welcome"));
const ChangePayment = lazy(() => import("./Student/ChangePayment"));
const FinancialDashboard = lazy(() => import("./Admin/FinancialDashboard"));

const EducationDashboard = lazy(() => import("./Admin/EducationDashboard"));
const ChangeProduct = lazy(() => import("./Student/ChangeProduct"));
const Config = lazy(() => import("./Admin/Config"));
const SetTimetable = lazy(() => import("./Tutor/SetTimetable"));
const SetMeeting = lazy(() => import("./Student/SetMeeting"));
const ViewMeetings = lazy(() => import("./Tutor/ViewMeetings"));
const Home = lazy(() => import("./Home/Home"));
const CreateAchievement = lazy(() => import("./Achievement/CreateAchievement"));
const EditAchievement = lazy(() => import("./Achievement/EditAchievement"));
const CreateStudentGroup = lazy(() =>
  import("./StudentGroup/CreateStudentGroup")
);
const EditStudentGroup = lazy(() => import("./StudentGroup/EditStudentGroup"));
const ViewChat = lazy(() => import("./Chat/ViewChat"));
const ViewStudentAchievement = lazy(() =>
  import("./Student/ViewStudentAchievement")
);
const Test = lazy(() => import("./Student/Test"));
const SubjectTest = lazy(() => import("./Student/SubjectTest"));
const IntroQuiz = lazy(() => import("./IntroQuiz/IntroQuiz"));
const ViewIntroQuiz = lazy(() => import("./IntroQuiz/ViewIntroQuiz"));
const EditDiagnostic = lazy(() =>
  import("./DiagnosticTest/EditDiagnosticTest")
);
const EditSkills = lazy(() => import("./Skill/EditSkills"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route
          element={
            <PrivateWrapper
              roles={["NO_ROLE", "STUDENT", "ADMIN", "SUPER_ADMIN"]}
            />
          }
        >
          {" "}
          <Route path="/payment" element={<FirstPayment />} />
        </Route>
        <Route element={<AppWrapper />}>
          <Route
            element={
              <PrivateWrapper
                roles={["TUTOR", "STUDENT", "ADMIN", "SUPER_ADMIN"]}
              />
            }
          >
            <Route path="/viewchat/:id" element={<ViewChat />} />
            <Route path="/updateProfile" element={<UpdateStudentProfile />} />
          </Route>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/introQuiz" element={<IntentionQuiz />} />
          <Route
            element={
              <PrivateWrapper roles={["STUDENT", "ADMIN", "SUPER_ADMIN"]} />
            }
          >
            <Route
              path="/viewachievements"
              element={<ViewStudentAchievement />}
            />
            <Route path="/college" element={<CIALFODashboard />} />
            <Route path="/setMeeting" element={<SetMeeting />} />
            <Route path="/buyMeeting" element={<BuyMeeting />} />
            <Route path="/paymentinfo" element={<PaymentInformation />} />
            <Route path="/changepayment" element={<ChangePayment />} />
            <Route path="/changeplan" element={<ChangeProduct />} />
            <Route path="/subscribe" element={<NewSubscription />} />
            <Route path="/c/:courseId/activity" element={<CourseReport />} />
            <Route
              path="/c/:courseId/sectionreport/:currentSection/next/:nextContent"
              element={<SectionReport />}
            />
            <Route path="/c/:courseId" element={<ViewCourseStudent />} />
            <Route
              path="/c/:courseId/:currentSectionGroup"
              element={<ViewCourseStudent />}
            />

            <Route path="/c/:courseId/v/:contentId" element={<Learn />} />
            <Route path="/c/:courseId/t/:contentId" element={<Test />} />
            <Route
              path="/c/:courseId/st/:contentId"
              element={<SubjectTest />}
            />
            <Route path="/c/:courseId/end" element={<EndCourse />} />
          </Route>
          <Route
            element={
              <PrivateWrapper roles={["ADMIN", "TUTOR", "SUPER_ADMIN"]} />
            }
          >
            <Route path="/givemark" element={<ReviewOpenQuestion />} />

            <Route path="/setavailability" element={<SetTimetable />} />
            <Route path="/viewmeetings" element={<ViewMeetings />} />
          </Route>
          <Route element={<PrivateWrapper roles={["SUPER_ADMIN"]} />}>
            <Route path="/config" element={<Config />} />
            <Route path="/editproduct/:id" element={<EditProduct />} />
            <Route path="/createproduct" element={<CreateProduct />} />
            <Route path="/createachievement" element={<CreateAchievement />} />
            <Route path="/editachievement/:id" element={<EditAchievement />} />
            <Route
              path="/createstudentgroup"
              element={<CreateStudentGroup />}
            />
            <Route
              path="/editstudentgroup/:id"
              element={<EditStudentGroup />}
            />
            <Route path="/editskills" element={<EditSkills />} />
            <Route path="/editcourse/:id" element={<EditCourse />} />
            <Route path="/createcourse" element={<CreateCourse />} />

            <Route path="/editContent/:id" element={<EditContent />} />

            <Route path="/createcontent" element={<CreateContent />} />
            <Route path="/EditDiagnostic" element={<EditDiagnostic />} />
          </Route>
          <Route element={<PrivateWrapper roles={["ADMIN", "SUPER_ADMIN"]} />}>
            <Route path="/productdashboard" element={<ProductDashboard />} />

            <Route path="/dashboard/finance" element={<FinancialDashboard />} />
            <Route
              path="/dashboard/education"
              element={<EducationDashboard />}
            />

            <Route path="/contentActivity" element={<ViewContentActivity />} />

            <Route path="/viewstudents" element={<ViewStudents />} />
            <Route
              path="/viewintroquiz/:studentId"
              element={<ViewIntroQuiz />}
            />
          </Route>
        </Route>
        <Route path="/introquiz/download" element={<ExtractIntroQuiz />} />
        <Route path="/diagnostic" element={<DiagnosticTest />} />
        <Route
          path="/"
          element={<Redirect url="https://www.hopon.institute/" />}
        />
        <Route path="/app" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Signout />} />
      </Routes>
    </Suspense>
  );
}

import React, { useContext } from 'react';
import { GiAlarmClock } from 'react-icons/gi';
import styled from 'styled-components';

import TestTimerContext from './Student/TestTimerContext';

export default function TestTimer() {
  const [currentTimer, setTimer] = useContext(TestTimerContext);
  return (
    <div>
      {currentTimer.isRunning && (
        <Timer isTimerRunningOut={currentTimer.isTimerRunningOut}>
          <GiAlarmClock alt={"Time spent on exercise"} data-tip="Time spent" />
          {currentTimer.text ?? "00:00"}
        </Timer>
      )}
    </div>
  );
}

const Timer = styled.div`
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: var(--accent);
  color: ${(props) => (props.isTimerRunningOut ? "red" : "white")};

  position: fixed;
  right: 5%;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  svg {
    font-size: 2rem;
  }
  z-index: 2000;
  border: 2px solid var(--background);
`;

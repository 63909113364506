import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';

import { ROLE_ADMIN, ROLE_TUTOR } from '../../consts';
import { useAuth } from '../../context/AuthContext';
import ChatMessageDataService from '../../services/chatMessage';
import ChatMessageFlagDataService from '../../services/chatMessageFlag';

export default function ModalFlagMessage(props) {
  const feebackRef = useRef();
  const [feedbackType, setFeedbackType] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [willDeleteMessage, setWillDeleteMessage] = useState(false);
  const [currentFlags, setCurrentFlags] = useState();
  const [canDeleteMessage, setCanDeleteMessage] = useState(false);
  const [currentChat, setCurrentChat] = useState();
  const { currentUser, getRole } = useAuth();
  function onSubmit(e) {
    e.preventDefault();
    setError("");
    console.log(feedbackType);
    if (feedbackType === null || feedbackType === "0") {
      setError("Please select feeedback type.");
      return;
    }

    setSuccess(true);
    ChatMessageFlagDataService.update(props.id, {
      flags: [
        ...currentFlags.flags,
        {
          author: currentUser.uid,
          type: feedbackType,
          details: feebackRef.current.value,
          deleteMessage: canDeleteMessage ? willDeleteMessage : false,
        },
      ],
    });

    var flaggedBy = currentChat.flaggedBy ?? [];
    if (!flaggedBy.includes(currentUser.uid)) {
      flaggedBy.push(currentUser.uid);
    }
    ChatMessageDataService.update(props.id, {
      flaggedBy: flaggedBy,
      isDeleted: canDeleteMessage ? willDeleteMessage : false,
    });
  }
  useEffect(() => {
    const fetchRole = async () => {
      setCanDeleteMessage([ROLE_ADMIN, ROLE_TUTOR].includes(await getRole()));
    };
    fetchRole();
  }, []);
  useEffect(() => {
    const fetchFlag = async () => {
      if (props.id) {
        const response = await ChatMessageFlagDataService.get(props.id);
        if (!response.exists()) {
          ChatMessageFlagDataService.create(props.id, { flags: [] });
        } else {
          setCurrentFlags(response.data());
        }
      }
    };
    fetchFlag();
    const fetchMessage = async () => {
      if (props.id) {
        const response = await ChatMessageDataService.get(props.id);

        setCurrentChat(response.data());
      }
    };
    fetchMessage();
  }, [props.id, props.show]);
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  }, [props.show]);

  function onChangeFeedbackType(e) {
    setFeedbackType(e.target.value);
  }
  return (
    <>
      <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flag chat message</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={onSubmit}>
            {success && (
              <Alert variant="success">Thank you for your feedback!</Alert>
            )}
            {!success && (
              <>
                {error !== "" && <Alert variant="danger">{error}</Alert>}
                <Form.Group className="mb-3">
                  <select
                    onChange={onChangeFeedbackType}
                    className="form-select"
                    aria-label="What's wrong with this message?"
                  >
                    <option defaultValue="0">
                      What's wrong with this message?
                    </option>
                    <option value="general">
                      This message should not be on Hop On.
                    </option>
                    <option value="private">
                      This message contains private or personal information.
                    </option>
                    <option value="unappropriated">
                      This message is unappropriated.
                    </option>
                    <option value="strange">
                      This user is behaving strangely or might be hacked.
                    </option>
                  </select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Feel free to share more information below:
                  </Form.Label>
                  <Form.Control as="textarea" ref={feebackRef} rows={4} />
                </Form.Group>
                {canDeleteMessage && (
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      label="Delete message"
                      checked={willDeleteMessage}
                      onChange={(e) => setWillDeleteMessage(e.target.checked)}
                    />
                  </Form.Group>
                )}
              </>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props.onClose} variant="secondary">
            Close
          </Button>
          {!success && (
            <Button onClick={onSubmit} variant="primary">
              Flag Comment
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const SET_PRODUCT = "app/product/set_product";
const SET_PRODUCT_INFO = "app/product/set_product_info";
const TOGGLE_COURSE_INCLUDE = "app/product/toggle_course_include";
const RESET_STATE = "app/reset_state";

const initialState = {
  id: "NO_ID",
  title: "Product Title",
  description: "",
  price: 0.0,
  isPurchasable: false,
  isTrialEnabled: false,
  trialDays: 0,
  setupFee: 0.0,
  holdSetupFee: true,
  courses: [],
  hasYTWC: false,
};
export function productCoursesReducer(state = [], action) {
  let courses = null;
  let index = null;
  switch (action.type) {
    case TOGGLE_COURSE_INCLUDE:
      if (state.includes(action.courseId)) {
        courses = [...state];
        index = courses.findIndex((e) => action.courseId === e);
        courses.splice(index, 1);
        return courses;
      } else {
        return [...state, action.courseId];
      }
    default:
      return [];
  }
}
export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT:
      return { ...action.product };
    case SET_PRODUCT_INFO:
      return {
        ...state,
        title: action.title,
        description: action.description,
        price: action.price,
        isPurchasable: action.isPurchasable,
        isTrialEnabled: action.isTrialEnabled,
        trialDays: action.trialDays,
        setupFee: action.setupFee,
        holdSetupFee: action.holdSetupFee,
      };
    case TOGGLE_COURSE_INCLUDE:
      return {
        ...state,
        courses: productCoursesReducer(state.courses, action),
      };
    case RESET_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
}

export const setProduct = (product) => ({
  type: SET_PRODUCT,
  product: product,
});

export const setProductInfo = (
  title,
  description,
  price,
  isPurchasable,
  isTrialEnabled,
  trialDays,
  setupFee,
  holdSetupFee
) => ({
  type: SET_PRODUCT_INFO,
  title: title,
  description: description,
  price: price,
  isPurchasable: isPurchasable,
  isTrialEnabled: isTrialEnabled,
  trialDays: trialDays,
  setupFee: setupFee,
  holdSetupFee: holdSetupFee,
});

export const toggleCourseInclude = (courseId) => ({
  type: TOGGLE_COURSE_INCLUDE,
  courseId: courseId,
});

import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { matchRoutes, useLocation } from 'react-router';
import styled from 'styled-components';

import { CHAT_BLOCKED_ROUTES } from '../../consts';
import ChatContainer from './ChatContainer';

export default function MiniChatContainer({
  chats,
  visibleChats,
  makeChatInvisible,
  onClick,
  numberPerChat,
  forceShowChatId,
}) {
  const [currentChatId, setCurrentChatId] = useState();
  const [currentChatElement, setCurrentChatElement] = useState();
  const [needReload, setNeedReload] = useState(false);
  const [chatPosition, setChatPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);

  const [isContainerVisible, setIsContainerVisible] = useState(true);
  const currentPath = useLocation();

  //make chat blocked in certain routes
  useEffect(() => {
    const route = matchRoutes(CHAT_BLOCKED_ROUTES, currentPath);

    if (route && route[0].route.path == "/c/:id/v/:id2") {
      setIsContainerVisible(false);
      setCurrentChatId();
    } else {
      setIsContainerVisible(true);
    }
  }, [currentPath]);

  function handleResize() {
    if (currentChatElement) {
      setChatPosition({
        x: currentChatElement.getBoundingClientRect().right,
        y: currentChatElement.getBoundingClientRect().top,
      });
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const element = {
      target: document.querySelector(".chat_pill_" + forceShowChatId),
    };

    if (element.target) {
      makeChatVisible(element, forceShowChatId);
    }
  }, [forceShowChatId]);
  function makeChatVisible(e, id) {
    if (id != currentChatId || !isVisible) {
      setIsVisible(false);
      setCurrentChatId();
      setNeedReload(!needReload);
      setCurrentChatElement(e.target);
      setTimeout(() => {
        setChatPosition({
          x: e.target.getBoundingClientRect().right,
          y: e.target.getBoundingClientRect().top,
        });
        setCurrentChatId(id);
        setIsVisible(true);
      }, 100);
    } else if (id == currentChatId) {
      setIsVisible(false);
      setCurrentChatId();
      setNeedReload(!needReload);
      setCurrentChatElement(e.target);
    }
  }

  function makeChatPillInvisible(id) {
    makeChatInvisible(id);
    setCurrentChatElement();
  }
  return (
    <>
      {isContainerVisible && (
        <MiniChatPillContainer onClick={() => onClick()}>
          <ChatContainer
            id={currentChatId}
            isMiniChat={true}
            needsReload={needReload}
            position={chatPosition}
            isChatVisible={isVisible && isContainerVisible}
          />
          {chats &&
            chats.map((obj, i) => {
              if (visibleChats.includes(obj.id)) {
                return (
                  <MiniChatPill
                    onClick={(e) => makeChatVisible(e, obj.id)}
                    className={"chat_pill_" + obj.id}
                    key={i}
                  >
                    {numberPerChat[obj.id] > 0 && (
                      <div className="unread">{numberPerChat[obj.id]}</div>
                    )}
                    <span>{obj.chat.title} </span>
                    <div onClick={(e) => makeChatPillInvisible(obj.id)}>
                      <MdClose />
                    </div>
                  </MiniChatPill>
                );
              }
            })}
        </MiniChatPillContainer>
      )}
    </>
  );
}
const MiniChatPillContainer = styled.div`
  position: fixed;
  right: 145px;
  bottom: 70px;
  display: flex;
  z-index: 1500;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  @media (max-width: 769px) {
    right: 95px;
    bottom: 105px;
  }
`;
const MiniChatPill = styled.div`
  background-color: var(--sevencolor);
  color: var(--white-text);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin-left: 1rem;
  display: flex;
  transition: visibility 0.1s linear, opacity 0.1s linear;
  align-items: center;

  div {
    margin-left: 0.8rem;
  }
  span {
    border-bottom: 1px solid var(--sevencolor);
  }
  span:hover {
    border-bottom: 1px solid #fff;
  }
  .unread {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: var(--white-text);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
`;

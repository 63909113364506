import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  doc,
  updateDoc,
  query,
  deleteDoc,
  where,
  setDoc,
  documentId,
} from "firebase/firestore";

const db = collection(firebase, "/userProfile");

class UserProfileDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, content: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/userProfile", id));
    return snapshot;
  }

  async getFromEmail(email) {
    const q = await getDocs(
      query(collection(firebase, "/userProfile"), where("email", "==", email))
    );

    return q.docs.map((doc) => ({ id: doc.id, user: doc.data() }));
  }
  async getFromId(id_list) {
    const q = await getDocs(
      query(
        collection(firebase, "/userProfile"),
        where(documentId(), "in", id_list)
      )
    );

    return q.docs.map((doc) => ({ id: doc.id, user: doc.data() }));
  }

  async getAllFromRole(role) {
    const q = await getDocs(
      query(collection(firebase, "/userProfile"), where("role", "==", role))
    );

    return q.docs.map((doc) => ({ id: doc.id, user: doc.data() }));
  }

  async create(userId, data) {
    const docRef = await setDoc(doc(firebase, "/userProfile", userId), data);
    return docRef;
  }

  async update(id, data) {
    await updateDoc(doc(firebase, "/userProfile", id), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/userProfile", id));
  }

  async getRole(uid) {
    const userProfile = await this.get(uid);
    if (userProfile.exists()) {
      return userProfile.data().role;
    }
    return null;
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new UserProfileDataService();

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthProvider } from "../context/AuthContext";
import store from "../store";

import AppContextProvider from "./AppContextProvider";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <AppContextProvider>
            <AppRoutes />
          </AppContextProvider>
        </AuthProvider>
      </Router>
    </Provider>
  );
}

export default App;

import React, { useState } from "react";
import { MdOutlineFeedback } from "react-icons/md";
import styled from "styled-components";

import ButtonStyled from "../../styles/Button";
import FeedbackModal from "./FeedbackModal";

export default function FeedbackIcon() {
  const [textVisible, setTextVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  function onMouseEnter(e) {
    setTextVisible(true);
  }
  function onMouseLeave(e) {
    setTextVisible(false);
  }
  function onClick(e) {
    setShowModal(true);
  }
  function onCloseModal() {
    setShowModal(false);
  }
  function onConfirmModal() {
    setShowModal(false);
  }
  return (
    <>
      <FeedbackModal
        show={showModal}
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
      />
      <ButtonContainer>
        {textVisible && <b> Feedback </b>}
        <ButtonFeedback
          id="buttonFeedback"
          onMouseEnter={(e) => onMouseEnter(e)}
          onMouseLeave={(e) => onMouseLeave(e)}
          onClick={(e) => onClick(e)}
        >
          <MdOutlineFeedback size={28} />
        </ButtonFeedback>
      </ButtonContainer>
    </>
  );
}
export const ButtonContainer = styled.div`
  position: fixed;
  right: 65px;
  bottom: 65px;
  vertical-align: center;
  height: 60px;
  @media (max-width: 769px) {
    right: 15px;
    bottom: 95px;
  }
`;
export const ButtonFeedback = styled(ButtonStyled)`
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;

import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { nanoid } from "nanoid";

const db = collection(firebase, "/notification");

class NotificationDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, content: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/notification", id));
    return snapshot;
  }

  async getFromTypeList(notification_type) {
    const q = await getDocs(
      query(
        collection(firebase, "/notification"),
        where("type", "in", notification_type)
      )
    );
    return q;
  }

  async create(notification_type, data) {
    const obj = { timestamp: Date.now(), data: data, type: notification_type };
    const docRef = await setDoc(doc(firebase, "notification", nanoid()), obj);
    return docRef;
  }

  async update(id, data) {
    await updateDoc(doc(firebase, "/notification", id), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/notification", id));
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new NotificationDataService();

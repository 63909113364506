import { collection, documentId, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { AiOutlineClose } from "react-icons/ai";
import { FiMessageCircle } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdNotifications } from "react-icons/md";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import EmptyProfilePicture from "../../assets/images/emptyprofile.png";
import MiniLogo from "../../assets/images/logo_mini.png";
import Logo from "../../assets/images/logo_white.png";
import { useAuth } from "../../context/AuthContext";
import { firebase } from "../../firebase";
import { setRole } from "../../redux/role";
import ChatNotifications from "../Chat/ChatNotifications";
import useOnScreen from "../useOnScreen";
import UserNotifications from "../UserNotifications/UserNotifications";
import AdminSidebar from "./AdminSidebar";
import StudentSidebar from "./StudentSidebar";
import SuperAdminSidebar from "./SuperAdminSidebar";
import TutorSidebar from "./TutorSidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../../styles/ProSidebar.scss";

export default function Sidebar() {
  const role = useSelector((state) => state.role);
  const [collapsed, setCollapsed] = useState(false);
  const { getRole } = useAuth();
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sideBar = [];
  const { getProfileInfo, currentUser } = useAuth();
  const [unread, setUnread] = useState(0);
  const [chatUnread, setChatUnread] = useState(0);
  const [snapshot, snapshotLoading, error] = useCollection(
    query(
      collection(firebase, "/userNotification"),
      where(documentId(), "==", currentUser?.uid ?? "undefined")
    )
  );
  const appBarRef = useRef();
  const isAppBarVisible = useOnScreen(appBarRef);

  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [notificationPosition, setNotificationPosition] = useState({
    x: 0,
    y: 0,
  });
  const [displayChatNotifications, setDisplayChatNotifications] = useState(
    false
  );
  const [chatNotificationPosition, setChatNotificationPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    function closeNotifications() {
      setDisplayNotifications(false);
      setDisplayChatNotifications(false);
    }

    window.addEventListener("mousedown", closeNotifications);

    return () => {
      window.removeEventListener("mousedown", closeNotifications);
    };
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      const role = await getRole();
      if(role){
      dispatch(setRole(role));}
    };

    fetchRole();
    const fetchProfile = async () => {
      var profile = await getProfileInfo();
      if (profile.exists()) {
        var data = profile.data();
        setProfilePictureURL(data.profilePicture);
        setUserName(data.firstName);
      }
    };
    fetchProfile();
  }, []);

  function showNotifications(e) {
    setNotificationPosition({ x: e.clientX, y: e.clientY });
    setDisplayNotifications(!displayNotifications);
    setDisplayChatNotifications(false);
  }
  function showChatNotifications(e) {
    setChatNotificationPosition({ x: e.clientX, y: e.clientY });
    setDisplayChatNotifications(!displayChatNotifications);
    setDisplayNotifications(false);
  }
  return (
    <>
      <UserNotifications
        show={displayNotifications}
        position={notificationPosition}
        setUnread={setUnread}
        isAppBarShowing={isAppBarVisible}
        snapshot={snapshot}
        loading={snapshotLoading}
      />
      <ChatNotifications
        show={displayChatNotifications}
        setShow={setDisplayChatNotifications}
        position={chatNotificationPosition}
        setUnread={setChatUnread}
        isAppBarShowing={isAppBarVisible}
        unread={chatUnread}
        userNotificationSnapshot={snapshot}
        userNotificationLoading={snapshotLoading}
      />
      <ProSidebar
        collapsed={collapsed}
        breakPoint={"md"}
        collapsedWidth={"120px"}
        onToggle={(value) => console.log(value)}
      >
        <div
          style={{
            position: "absolute",
            right: "10px",
            zIndex: 999,
          }}
        >
          <button
            className="link-button"
            style={{ color: "var(--white-text)" }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? (
              <AiOutlineClose pointerEvents="none" />
            ) : (
              <GiHamburgerMenu pointerEvents="none" />
            )}
          </button>
        </div>
        <Menu iconShape="circle">
          <SidebarHeader style={{ margin: "25px 0" }}>
            <Link to="/home">
              {collapsed ? (
                <img className="logo" src={MiniLogo} alt="Hop On" />
              ) : (
                <MenuItem>
                  <img className="logo" src={Logo} alt="Hop On" />
                </MenuItem>
              )}
            </Link>
          </SidebarHeader>
          <SidebarContent>
            <UserPictureMenuIntem
              onClick={(e) => navigate("/home")}
              collapsed={collapsed}
            >
              <div>
                <img
                  src={
                    profilePictureURL !== ""
                      ? profilePictureURL
                      : EmptyProfilePicture
                  }
                  alt="Foto de perfil"
                  className="img-profile-small"
                />
              </div>
              <div className="username">
                {userName === "" ? "Student" : userName}
              </div>
              <div
                className="userNotificationsIcon"
                onClick={(e) => showNotifications(e)}
                data-tip="Notifications"
              >
                {unread > 0 && <div className="unreadMarker">{unread}</div>}
                <MdNotifications />
              </div>
              <div
                className="messageNotificationIcon"
                onClick={(e) => showChatNotifications(e)}
                data-tip="Messages"
              >
                {chatUnread > 0 && (
                  <div className="unreadMarker">{chatUnread}</div>
                )}
                <FiMessageCircle />
              </div>
            </UserPictureMenuIntem>
            {role && role === "STUDENT" && <StudentSidebar />}
            {role && role === "ADMIN" && <AdminSidebar />}
            {role && role === "SUPER_ADMIN" && <SuperAdminSidebar />}
            {role && role === "TUTOR" && <TutorSidebar />}
          </SidebarContent>
        </Menu>
      </ProSidebar>

      <AppBar ref={appBarRef}>
        <MenuItem
          onClick={(e) => showNotifications(e)}
          icon={<MdNotifications />}
          data-tip="Notifications"
          className="userNotificationsIcon"
          style={{ overflow: "visible" }}
        >
          {unread > 0 && <div className="unreadMarker">{unread}</div>}
        </MenuItem>
        <MenuItem
          style={{ overflow: "visible" }}
          onClick={(e) => showChatNotifications(e)}
          icon={<FiMessageCircle />}
          data-tip="Messages"
          className="userNotificationsIcon"
        >
          {chatUnread > 0 && <div className="unreadMarker">{chatUnread}</div>}
        </MenuItem>
        {role && role === "STUDENT" && <StudentSidebar />}
        {role && role === "ADMIN" && <AdminSidebar />}
        {role && role === "SUPER_ADMIN" && <SuperAdminSidebar />}
        {role && role === "TUTOR" && <TutorSidebar />}
      </AppBar>
    </>
  );
}

const UserPictureMenuIntem = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.collapsed ? "column" : "row")};
  padding: ${(props) => (props.collapsed ? "0px" : "8px 35px 8px 5px")};
  align-items: center;
  justify-content: ${(props) => (props.collapsed ? "center" : "left")};
  @keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(10deg);
    }

    40% {
      transform: rotate(-10deg);
    }

    60% {
      transform: rotate(5deg);
    }

    80% {
      transform: rotate(-5deg);
    }
  }
  .unreadMarker {
    background-color: red;
    color: var(--white-text);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -6px;
    right: -6px;
  }
  .userNotificationsIcon,
  .messageNotificationIcon {
    background-color: rgba(255, 255, 255, 0.05);
    width: 35px;
    height: 35px;
    position: ${(props) => (props.collapsed ? "relative" : "absolute")};
    right: ${(props) => (props.collapsed ? "0.15rem" : "1rem")};
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: ${(props) => (props.collapsed ? "0.4rem" : "0")};
  }
  .messageNotificationIcon {
    right: ${(props) => (props.collapsed ? "0.15rem" : "4rem")};
  }
  .userNotificationsIcon:hover,
  .messageNotificationIcon:hover {
    animation: swing ease-in-out 0.5s 1 alternate;
  }
  .username {
    vertical-align: center;
    display: ${(props) => (props.collapsed ? "none" : "block")};
    width: 5rem;
    overflow: hidden;
  }
`;
const AppBar = styled.div`
  position: fixed;
  height: 80px;
  color: var(--white-text);
  bottom: 0;
  background-color: rgb(0, 47, 108);
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  .pro-menu-item {
    transition: width 0.3s;
    width: 45px;
    padding: 1px 1px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;

    overflow: hidden;
    a {
      display: none;
    }
    span {
      font-size: 15px;
    }
    &:hover {
      color: #dee2ec;
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { nanoid } from "nanoid";

const db = collection(firebase, "/chatMessage");

class ChatMessageDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, content: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/chatMessage", id));
    return snapshot;
  }

  async getFromChat(id) {
    const q = await getDocs(
      query(collection(firebase, "/chatMessage"), where("chat", "==", id))
    );

    return q.docs.map((doc) => ({ id: doc.id, message: doc.data() }));
  }
  async create(data) {
    const docRef = await setDoc(doc(firebase, "chatMessage", nanoid()), data);
    return docRef;
  }

  async update(id, data) {
    await updateDoc(doc(firebase, "/chatMessage", id), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/chatMessage", id));
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new ChatMessageDataService();

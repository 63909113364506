import { collection, query, where } from "firebase/firestore";
import { DATE_SHORT, DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { GrExpand } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import EmptyProfilePicture from "../../assets/images/emptyprofile.png";
import {
  CHAT_DELETED_MESSAGE,
  CHAT_TOO_MANY_FLAGS,
  CHAT_TOO_MANY_FLAGS_NUMBER,
  ROLE_ADMIN,
  ROLE_STUDENT,
  ROLE_SUPER_ADMIN,
} from "../../consts";
import { useAuth } from "../../context/AuthContext";
import { firebase } from "../../firebase";
import ChatDataService from "../../services/chat";
import ChatMessageDataService from "../../services/chatMessage";
import UserNotificationDataService from "../../services/userNotification";
import UserProfileDataService from "../../services/userProfile";
import Error404 from "../Error404";
import ChatMessage from "./ChatMessage";
import ModalFlagMessage from "./ModalFlagMessage";

export default function ChatContainer({
  id,
  isMiniChat,
  position,
  needReload,
  isChatVisible,
}) {
  const [chatNotFound, setChatNotFound] = useState(false);
  const [chat, setChat] = useState();
  const [title, setTitle] = useState("");
  const [messages, setMessages] = useState();
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const chatMessagesRef = useRef();
  const { currentUser, getRole } = useAuth();
  const [userOutsideChat, setUserOutsideChat] = useState({});
  const [showFirstMessageIcon, setShowFirstMessageIcon] = useState(false);
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [flaggedMessageId, setFlaggedMessageId] = useState();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const currentMessage = useRef();
  const chatContainerRef = useRef();
  const navigate = useNavigate();
  const [snapshot, loading, error] = useCollection(
    query(collection(firebase, "/chatMessage"), where("chat", "==", id ?? ""))
  );
  function viewLastMessage() {
    if (!chatMessagesRef.current) return;
    var objDiv = chatMessagesRef.current;

    objDiv.scrollTop = objDiv.scrollHeight;
    setShowFirstMessageIcon(false);
  }
  function onScroll() {
    if (!chatMessagesRef.current) {
      return;
    }
    var objDiv = chatMessagesRef.current;

    if (objDiv.scrollHeight - objDiv.scrollTop > 1500) {
      setShowFirstMessageIcon(true);
    } else {
      setShowFirstMessageIcon(false);
    }
  }

  const markAsRead = async () => {
    const response = await UserNotificationDataService.get(currentUser.uid);
    if (response.exists()) {
      var data = response.data().unreadMessages;

      if (data && id in data && data[id] != 0) {
        data[id] = 0;
        await UserNotificationDataService.update(currentUser.uid, {
          unreadMessages: data,
        });
      }
    }
  };

  //read all messages when fullscreen
  useEffect(() => {
    if (!isMiniChat) {
      markAsRead();
    }
  }, []);

  function resetChat() {
    if (isMiniChat) {
      setIsVisible(false);
      setIsLoading(true);
      setMessages();
    } else {
      setIsVisible(true);
    }
  }
  useEffect(() => {
    resetChat();
  }, [needReload]);
  const fetchChat = async () => {
    try {
      const response = await ChatDataService.get(id);
      var role = await getRole();
      //Super admins can view deleted chats
      if (
        !response.exists() ||
        (response.exists() &&
          response.data().isDeleted &&
          role != ROLE_SUPER_ADMIN)
      ) {
        setChatNotFound(true);
      } else if (
        !response.data().users.includes(currentUser.uid) &&
        ![ROLE_SUPER_ADMIN, ROLE_ADMIN].includes(role)
      ) {
        setChatNotFound(true);
      } else {
        setChat(response.data());
        setTitle(response.data().title);
      }
    } catch (err) {
      console.error(err);
    }
  };
  function closeChat(e) {
    if (chatContainerRef.current && isChatVisible) {
      if (
        e.clientX < chatContainerRef.current.getBoundingClientRect().left ||
        e.clientX > chatContainerRef.current.getBoundingClientRect().right ||
        e.clientY < chatContainerRef.current.getBoundingClientRect().top ||
        e.clientY > chatContainerRef.current.getBoundingClientRect().bottom
      ) {
        resetChat();
      }
    }
  }

  useEffect(() => {
    if (isMiniChat) {
      window.addEventListener("mousedown", closeChat);
      return () => {
        window.removeEventListener("mousedown", closeChat);
      };
    }
  });

  useEffect(() => {
    resetChat();
    if (chatMessagesRef.current) {
      chatMessagesRef.current.addEventListener("scroll", onScroll);
    }

    if (id) {
      fetchChat();
    }
  }, [id]);

  useEffect(() => {
    const fetchChatUsers = async () => {
      try {
        if (chat) {
          const response = await UserProfileDataService.getFromId(chat.users);
          setUsers(response);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchChatUsers();
  }, [chat]);
  function findUser(id) {
    const res = users.filter((obj) => obj.id == id);

    if (res.length == 0) {
      return undefined;
    }
    return res[0];
  }

  const fetchUser = async (id) => {
    try {
      const response = await UserProfileDataService.get(id);
      return response;
    } catch (err) {
      console.error(err);
    }
  };
  function parseMessages(messages) {
    var result = [];
    result = messages.map(async (obj) => {
      const user = findUser(obj.message.author);
      var date = new DateTime.fromJSDate(new Date(obj.message.timestamp));
      var res = {
        id: obj.id,
        ...obj.message,
        timestamp: date,
        date: date.toLocaleString(DATE_SHORT),
      };
      res.outcoming = obj.message.author == currentUser.uid;
      if (user) {
        res.authorProfilePicture = user.user.profilePicture;
        res.name = user.user.firstName;
        res.isStudent = user.user.role == ROLE_STUDENT;
        return res;
      } else if (obj.message.author in userOutsideChat) {
        res.authorProfilePicture =
          userOutsideChat[obj.message.author].profilePicture;
        res.name = userOutsideChat[obj.message.author].firstName;
        res.isStudent =
          userOutsideChat[obj.message.author].role == ROLE_STUDENT;
        return res;
      } else {
        var newUserOutsideChat = { ...userOutsideChat };
        if (!(obj.message.author in newUserOutsideChat)) {
          newUserOutsideChat[obj.message.author] = fetchUser(
            obj.message.author
          );
          setUserOutsideChat(newUserOutsideChat);
        }
        //console.log(newUserOutsideChat[obj.message.author]);
        const outsideUser = await newUserOutsideChat[obj.message.author];

        //console.log(outsideUser.data());

        res.authorProfilePicture = outsideUser.data().profilePicture;
        res.name = outsideUser.data().firstName;
        res.isStudent = outsideUser.data().role == ROLE_STUDENT;
        res.isOutsideChat = true;
        //console.log(res);
        return res;
      }
    });

    Promise.all(result).then((result) => {
      result.sort((a, b) => a.timestamp - b.timestamp);

      setMessages(result);
      viewLastMessage();
      if (isMiniChat && isLoading) {
        setIsLoading(false);
        setIsVisible(true);
        markAsRead();
      }
    });
  }

  useEffect(() => {
    if (!messages && isMiniChat) {
      setIsLoading(true);
      setIsVisible(false);
    }
    if (users && !loading) {
      parseMessages(
        snapshot.docs.map((doc) => ({ id: doc.id, message: doc.data() }))
      );
      viewLastMessage();
    }
  }, [loading, snapshot, users, userOutsideChat]);

  function sendMessage() {
    viewLastMessage();
    if (currentMessage.current.value.replaceAll(/\s/g, "").length == 0) {
      return;
    }
    ChatMessageDataService.create({
      chat: id,
      author: currentUser.uid,
      message: currentMessage.current.value,
      timestamp: new Date().toLocaleString(),
    });
    currentMessage.current.value = "";
  }

  function onShowModalFlag(id) {
    setFlaggedMessageId(id);
    setShowModalFlag(true);
  }
  function onCloseModalFlag(id) {
    setShowModalFlag(false);
  }

  function getYPosition() {
    var positionY = position?.y ?? 0;

    if (isMiniChat && chatContainerRef.current) {
      positionY -= chatContainerRef.current.getBoundingClientRect().height;
    }
    return positionY;
  }
  function getXPosition() {
    var positionX = position?.x ?? 0;

    if (isMiniChat && chatContainerRef.current) {
      positionX -= chatContainerRef.current.getBoundingClientRect().width;
    }
    return positionX;
  }
  return (
    <Chat
      isMiniChat={isMiniChat}
      positionX={getXPosition()}
      positionY={getYPosition()}
      isVisible={(isChatVisible && isVisible && !isLoading) ?? false}
      ref={chatContainerRef}
    >
      {chatNotFound && <Error404 />}
      {!chatNotFound && (
        <div className="chat">
          <ModalFlagMessage
            show={showModalFlag}
            onClose={onCloseModalFlag}
            onConfirm={onCloseModalFlag}
            id={flaggedMessageId}
          />
          <div className="title">
            <div> {title}</div>
            {isMiniChat && (
              <div>
                <div onClick={() => navigate("/viewchat/" + id)}>
                  <GrExpand />
                </div>
                <div onClick={() => setIsVisible(false)}>
                  <MdClose />
                </div>
              </div>
            )}
          </div>
          {isLoading && (
            <div className="messageContainer">
              <div className="messages">
                <DateSeparator>
                  {" "}
                  <span>Today</span>
                </DateSeparator>
                <div className="loadingContainer"></div>
              </div>
            </div>
          )}
          {!isLoading && (
            <div className="userList">
              {users &&
                Object.entries(users).map(([k, object]) => (
                  <div className="user" key={k}>
                    <img
                      src={
                        object.user.profilePicture == ""
                          ? EmptyProfilePicture
                          : object.user.profilePicture
                      }
                      className={
                        object.user.role == "STUDENT"
                          ? "img-profile-small"
                          : "img-profile-small tutor"
                      }
                    />
                    {object.user.firstName + " " + object.user.lastName}
                  </div>
                ))}
            </div>
          )}
          {!isLoading && (
            <div className="messageContainer">
              <div className="messages" ref={chatMessagesRef}>
                {(!messages || messages.length == 0) && (
                  <>
                    <DateSeparator>
                      {" "}
                      <span>Today</span>
                    </DateSeparator>
                    <p id="center-content">No messages to show.</p>
                  </>
                )}
                {messages &&
                  messages.map((obj, i) => {
                    if (obj.isDeleted) {
                      obj.message = CHAT_DELETED_MESSAGE;
                    } else if (
                      obj.flaggedBy &&
                      obj.flaggedBy.length >= CHAT_TOO_MANY_FLAGS_NUMBER
                    ) {
                      obj.message = CHAT_TOO_MANY_FLAGS;
                    }
                    return (
                      <div key={i}>
                        {(i == 0 || obj.date != messages[i - 1].date) && (
                          <DateSeparator>
                            {" "}
                            <span>{obj.date}</span>
                          </DateSeparator>
                        )}
                        <ChatMessage
                          obj={obj}
                          i={i}
                          messages={messages}
                          onShowModalFlag={onShowModalFlag}
                          isMiniChat={isMiniChat}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {showFirstMessageIcon && (
            <div className="firstMessageIcon" onClick={() => viewLastMessage()}>
              <BsArrowDownCircleFill />
            </div>
          )}
          <div>
            <Form.Group className="mb-3 sendContainer">
              <Form.Control
                type="input"
                ref={currentMessage}
                onKeyUp={(e) => {
                  e.preventDefault();

                  if (e.keyCode === 13) {
                    sendMessage();
                  }
                }}
              />
              <Button variant="primary" onClick={() => sendMessage()}>
                Send
              </Button>
            </Form.Group>
          </div>
        </div>
      )}
    </Chat>
  );
}

const Chat = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  max-width: ${(props) => (props.isMiniChat ? "30rem" : "40rem")};
  margin: 0 auto;
  position: ${(props) => (props.isMiniChat ? "fixed" : "relative")};
  left: ${(props) => (props.isMiniChat ? props.positionX + "px" : "")};
  top: ${(props) => (props.isMiniChat ? props.positionY + "px" : "")};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  z-index: ${(props) => (props.isMiniChat ? "9999" : "")};
  background: #fff;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  padding: ${(props) => (props.isMiniChat ? "0rem 1rem" : "")};
  .loadingContainer {
    height: 250px;
    display: block;
    position: relative;
  }
  .title {
    font-size: ${(props) => (props.isMiniChat ? "1.1rem" : "1.3rem")};
    margin: ${(props) => (props.isMiniChat ? "1rem 0rem" : "0 auto")};
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    display: flex;
    justify-content: ${(props) =>
      props.isMiniChat ? "space-between" : "center"};
  }
  .title div {
    display: flex;
    justify-content: space-between;
  }
  .userList {
    align-items: center;
    justify-content: space-evenly;
    display: ${(props) => (props.isMiniChat ? "none" : "flex")};
  }
  .messageContainer {
    padding: ${(props) => (props.isMiniChat ? "" : "2rem")};
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    margin-bottom: ${(props) => (props.isMiniChat ? "1rem" : "2rem")};
  }
  .sendContainer {
    display: flex;
    flex-direction: row;
    margin-top: ${(props) => (props.isMiniChat ? "1rem" : "2rem")};
    gap: ${(props) => (props.isMiniChat ? "1rem" : "2rem")};
  }
  .messages {
    max-height: 45vh;
    min-height: 300px;
    overflow: scroll;
  }
  .messages :hover .flag {
    opacity: 1;
  }
  .firstMessageIcon {
    color: var(--fourteenthcolor);
    font-size: 3rem;
    position: absolute;
    right: 45%;
    bottom: 25%;
  }
  .firstMessageIcon > svg:hover {
    opacity: 0.6;
  }
`;
const DateSeparator = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  span {
    margin: 0 1rem;
  }
  &::before,
  &:after {
    display: block;
    content: " ";
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    flex: 1 1 auto;
  }
`;

import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../context/AuthContext";
import { setProfile } from "../../redux/profile";
import QuizDataService from "../../services/quiz";
import UserProfileDataService from "../../services/userProfile";
import styled from "styled-components";
const TESTS = ["TOEFL", "IELTS", "DUOLINGO", "FCE", "CAE", "Other"];
const APP_TESTS = ["SAT", "ACT", "G-MAT", "GRE"];

export default function IntentionQuiz({ studentId }) {
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const userId = studentId ?? currentUser.uid;

  const [disabled, setDisabled] = useState(false);
  const [hasApplied, setHasApplied] = useState("No");
  const [hasBeenAccepted, setHasBeenAccepted] = useState("No");
  const [applicationKnowledgeSelect, setApplicationKnowledgeSelect] = useState(
    ""
  );
  const [goalOtherText, setGoalOtherText] = useState("");
  const [englishProfSelect, setEnglishProfSelect] = useState("");
  const [hasEnglishProfSelect, setHasEnglishProfSelect] = useState("");
  const [profTestCheckbox, setProfTestCheckbox] = useState(
    new Array(TESTS.length).fill(false)
  );
  const [appTestCheckbox, setAppTestCheckbox] = useState(
    new Array(APP_TESTS.length).fill(false)
  );
  const [englishTestDict, setEnglishTestDict] = useState([]);
  const [applicationTestDict, setApplicationTestDict] = useState([]);
  const [hasApplication, setHasApplication] = useState("");
  const [bilingualSchoolSelect, setBilingualSchoolSelect] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [applicationInstitution, setApplicationInstitution] = useState("");
  const [applicationTarget, setApplicationTarget] = useState("");
  const [applicationKnowledge, setApplicationKnowledge] = useState("");
  const [applicationObjective, setApplicationObjective] = useState("");
  const [hasApplicationList, setHasApplicationList] = useState("");
  const [applicationGoal, setApplicationGoal] = useState("");
  const [linkedIn, setLinkedIn] = useState("");

  const [error, setError] = useState("");

  const [step, setStep] = useState(1);

  function handleProfTestOnChange(index) {
    const updatedCheckedState = profTestCheckbox.map((item, i) =>
      i === index ? !item : item
    );

    setProfTestCheckbox(updatedCheckedState);
    var tests = updatedCheckedState
      .map((obj, i) => (obj ? { test: [TESTS[i]], grade: "" } : ""))
      .filter((obj) => obj != "");

    setEnglishTestDict(tests);
  }

  function handleAppTestOnChange(index) {
    const updatedCheckedState = appTestCheckbox.map((item, i) =>
      i === index ? !item : item
    );

    setAppTestCheckbox(updatedCheckedState);
    var tests = updatedCheckedState
      .map((obj, i) => (obj ? { test: [APP_TESTS[i]], grade: "" } : ""))
      .filter((obj) => obj != "");

    setApplicationTestDict(tests);
  }
  useEffect(() => {
    if (!studentId) {
      const fetchProfile = async () => {
        const response = await UserProfileDataService.get(currentUser.uid);
        if (!response.exists() || response.data().status !== "ACTIVE") {
          navigate("/onboarding");
        } else {
          dispatch(setProfile(response.data()));
        }
      };
      fetchProfile();
    }
  }, []);

  function setEnglishGradeTest(index, value) {
    var newEnglishTestDict = [...englishTestDict];
    newEnglishTestDict[index].grade = value;
    console.log(newEnglishTestDict);
    setEnglishTestDict(newEnglishTestDict);
  }
  function setApplicationGradeTest(index, value) {
    var newApplicationTestDict = [...applicationTestDict];
    newApplicationTestDict[index].grade = value;
    console.log(newApplicationTestDict);
    setApplicationTestDict(newApplicationTestDict);
  }
  function onSubmit() {
    /*setError();
    setDisabled(true);
    if (!applicationKnowledgeSelect || applicationKnowledgeSelect === "") {
      setError("Please select your goals.");
      setDisabled(false);
      return;
    }

    if (applicationKnowledgeSelect == "GoalOther" && goalOtherText === "") {
      setError("Please describe your goals.");
      setDisabled(false);
      return;
    }
    if (!englishProfSelect || englishProfSelect === "") {
      setError("Please select your English Proficiency level.");
      setDisabled(false);
      return;
    }

    if (hasApplication == "Yes" && applicationInstitution == "") {
      setError("Please fill which institutions you have applied to.");
      setDisabled(false);
      return;
    }
    if (hasApplication == "Yes" && applicationStatus == "") {
      setError("Please describe the status of your applications.");
      setDisabled(false);
      return;
    }

    

    if (hasApplicationList == "Yes" && applicationTarget == "") {
      setError("Please fill which institutions you plan to apply to.");
      setDisabled(false);
      return;
    }*/

    const result = {
      applicationKnowledgeSelect: applicationKnowledgeSelect,
      englishProfSelect: englishProfSelect,
      bilingualSchoolSelect: bilingualSchoolSelect,
      hasEnglishProfSelect: hasEnglishProfSelect,
      englishTestDict: englishTestDict,
      applicationGoal: applicationGoal,
      applicationTestDict: applicationTestDict,
      applicationObjective: applicationObjective,
      hasApplied: hasApplied,
      hasBeenAccepted: hasBeenAccepted,
    };
    QuizDataService.create(currentUser.uid, { introQuiz: result }).then(() =>
      toast.success("Introduction Quiz sent! ", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => navigate("/home"),
      })
    );
  }

  {
    /*
  useEffect(() => {
    const fetchQuiz = async () => {
      const response = await QuizDataService.get(userId);
      if (response.exists() && response.data().introQuiz) {
        const data = response.data().introQuiz;
        console.log(data);
        setDisabled(true);
        setGoalSelect(data.goalSelect);
        setGoalOtherText(data.goalOtherText);
        setEnglishProfSelect(data.englishProfSelect);
        setHasEnglishProfSelect(data.hasEnglishProfSelect);
        setEnglishTestDict(data.englishTestDict);
        setHasApplication(data.hasApplication);
        setApplicationStatus(data.applicationStatus);
        setApplicationInstitution(data.applicationInstitution);
        setProfTestCheckbox(data.profTestCheckbox);
        setApplicationTarget(data.applicationTarget);
        setApplicationKnowledge(data.applicationKnowledge);
        setApplicationObjective(data.applicationObjective);
        setHasApplicationList(data.hasApplicationList);
        setLinkedIn(data.linkedIn);
      }
    };
    fetchQuiz();
  }, [userId]);*/
  }

  const onPreviousQuestion = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };
  const onNextQuestion = () => {
    setError("");
    if (step == 1 && applicationKnowledgeSelect == "") {
      setError("Select an option");
      return;
    }
    if (step == 2 && englishProfSelect == "") {
      setError("Select an option");
      return;
    }
    if (step == 5 && applicationGoal == "") {
      setError("Select an option");
      return;
    }
    if (step == 5) {
      if (applicationGoal == "University" && applicationObjective == "") {
        setError("Please select which kind of course you plan to attend.");

        return;
      }
    }

    if (step < 6) {
      setStep((prev) => prev + 1);
    }
  };
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "600px" }}>
        <Card
          className="w-100"
          style={{
            borderRadius: "5%",
            maxWidth: "600px",
            background: "#002f6c",
            color: "var(--white-text)",
            textAlign: "center",
            padding: "15px",
          }}
        >
          <br />
          <h4>Introduction Quiz:</h4>
          <br />
          <p>
            It’s good to have you here! We are looking forward to helping you
            achieve your goals. For such a purpose, we need you to provide some
            information about you and your path. This way, we can indicate which
            of our services best work for you at the moment.
          </p>
          <ItemList>
            <li className={step == 1 ? "active" : ""}>1</li>
            <li className={step == 2 ? "active" : ""}>2</li>
            <li className={step == 3 ? "active" : ""}>3</li>
            <li className={step == 4 ? "active" : ""}>4</li>
            <li className={step == 5 ? "active" : ""}>5</li>
            <li className={step == 6 ? "active" : ""}>6</li>
          </ItemList>
          {error && <Alert variant="danger">{error}</Alert>}
          {step == 1 && (
            <Form.Group className="mb-3">
              <Form.Label>
                How would you define your knowledge regarding the application
                process for universities abroad?
                <span className="required">*</span>
              </Form.Label>
              <Form.Select
                onChange={(e) => setApplicationKnowledgeSelect(e.target.value)}
                value={applicationKnowledgeSelect}
                disabled={disabled}
              >
                <option value="">Select one option</option>
                <option value="Nothing">
                  I don’t know anything about it, help me!
                </option>
                <option value="ManyDoubts">
                  I kind of know some things, but I have many doubts!
                </option>
                <option value="Everything">
                  I’m a pro! I just need someone to tutor me along the way.
                </option>
              </Form.Select>
            </Form.Group>
          )}
          {step == 2 && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>
                  Your proficiency level<span className="required">*</span>
                </Form.Label>
                <Form.Select
                  disabled={disabled}
                  onChange={(e) => setEnglishProfSelect(e.target.value)}
                  value={englishProfSelect}
                >
                  <option value="">Selecione opção</option>
                  <option value="A1">Beginner - A1</option>
                  <option value="A2">Basic - A2</option>
                  <option value="B1">Intermediate - B1</option>
                  <option value="B2">Intermediate-advanced - B2</option>
                  <option value="C1">Advanced - C1</option>
                  <option value="C2">Advanced-proficient - C2</option>
                  <option value="NotKnown">I don't knowi</option>
                </Form.Select>
              </Form.Group>
            </>
          )}
          {step == 3 && (
            <Form.Group className="mb-3">
              <Form.Label>
                Do/did you attend a bilingual school in the past 2 year
              </Form.Label>
              <Form.Select
                onChange={(e) => setBilingualSchoolSelect(e.target.value)}
                value={bilingualSchoolSelect}
                disabled={disabled}
              >
                <option value="No">No, I don’t/didn’t.</option>
                <option value="Yes">Yes, I do/did.</option>
              </Form.Select>
            </Form.Group>
          )}
          {step == 4 && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>
                  Did you ever take an English proficiency test?
                </Form.Label>
                <Form.Select
                  onChange={(e) => setHasEnglishProfSelect(e.target.value)}
                  value={hasEnglishProfSelect}
                  disabled={disabled}
                >
                  <option value="No">No, I didn’t.</option>
                  <option value="Yes">Yes, I did.</option>
                </Form.Select>
              </Form.Group>
              {hasEnglishProfSelect == "Yes" && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Which one(s)? You may select more than one option:
                    </Form.Label>
                    {profTestCheckbox.map((obj, i) => (
                      <Form.Check
                        disabled={disabled}
                        type={"checkbox"}
                        id={"ProfTest" + TESTS[i]}
                        checked={obj}
                        onChange={() => handleProfTestOnChange(i)}
                        label={TESTS[i]}
                      />
                    ))}
                  </Form.Group>
                  {englishTestDict.map((value, index) => (
                    <Form.Group className="mb-3" key={value.test}>
                      <Form.Label>{value.test} Score: </Form.Label>
                      <Form.Control
                        disabled={disabled}
                        type="input"
                        value={value.grade}
                        onChange={(e) =>
                          setEnglishGradeTest(index, e.target.value)
                        }
                      />
                    </Form.Group>
                  ))}
                </>
              )}{" "}
            </>
          )}
          {step == 5 && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>What is your final goal? </Form.Label>
                <Form.Select
                  disabled={disabled}
                  onChange={(e) => setApplicationGoal(e.target.value)}
                  value={applicationGoal}
                >
                  <option value="">Select one option</option>
                  <option value="Improve">Improve my English, mostly.</option>
                  <option value="Proficiency">
                    Prepare myself for proficiency tests.
                  </option>
                  <option value="University">
                    Apply to universities abroad.
                  </option>
                </Form.Select>
              </Form.Group>

              {applicationGoal == "University" && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    I would like to apply to:{" "}
                    <span className="required">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    disabled={disabled}
                    value={applicationObjective}
                    onChange={(e) => setApplicationObjective(e.target.value)}
                  >
                    <option value="">Select option</option>

                    <option value="HighSchool">High School abroad</option>
                    <option value="ShortDurationCourse">
                      Short-term course abroad (e.g. summer programs)
                    </option>
                    <option value="Undergraduate">
                      Undergraduate school abroad
                    </option>

                    <option value="Graduate">Graduate school abroad</option>
                  </Form.Select>
                </Form.Group>
              )}
              {(applicationObjective == "Undergraduate" ||
                applicationObjective == "Graduate") && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    Did you ever take any admission test?{" "}
                  </Form.Label>

                  {appTestCheckbox.map((obj, i) => (
                    <Form.Check
                      disabled={disabled}
                      type={"checkbox"}
                      id={"AppTest" + APP_TESTS[i]}
                      checked={obj}
                      onChange={() => handleAppTestOnChange(i)}
                      label={APP_TESTS[i]}
                    />
                  ))}
                  {applicationTestDict.map((value, index) => (
                    <Form.Group className="mb-3" key={value.test}>
                      <Form.Label>{value.test} Score: </Form.Label>
                      <Form.Control
                        disabled={disabled}
                        type="input"
                        value={value.grade}
                        onChange={(e) =>
                          setApplicationGradeTest(index, e.target.value)
                        }
                      />
                    </Form.Group>
                  ))}
                </Form.Group>
              )}
            </>
          )}
          {step == 6 && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>
                  Have you ever applied to any institution abroad? (High school,
                  summer school, undergraduate, etc.)
                </Form.Label>
                <Form.Select
                  onChange={(e) => setHasApplied(e.target.value)}
                  value={hasApplied}
                  disabled={disabled}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </Form.Select>
              </Form.Group>
              {hasApplied == "Yes" && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Were you admitted to any of them?</Form.Label>
                    <Form.Select
                      onChange={(e) => setHasBeenAccepted(e.target.value)}
                      value={hasBeenAccepted}
                      disabled={disabled}
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                  </Form.Group>
                </>
              )}
            </>
          )}
          <SubmitButton>
            {step < 6 && (
              <Form.Group id="center-content">
                <Button
                  variant="secondary"
                  onClick={() => onPreviousQuestion()}
                >
                  Back
                </Button>
                <Button variant="success" onClick={() => onNextQuestion()}>
                  Continue
                </Button>
              </Form.Group>
            )}
            {step == 6 && (
              <Form.Group id="center-content">
                {" "}
                <Button
                  variant="secondary"
                  onClick={() => onPreviousQuestion()}
                >
                  Back
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Submit
                </Button>
              </Form.Group>
            )}
          </SubmitButton>
        </Card>
      </div>
    </Container>
  );
}
const SubmitButton = styled.div`
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;

  div {
    display: flex;
    gap: 3rem;
  }
  button {
    background-color: var(--support--pink--30);
    color: var(--accent);
  }
  button:hover,
  button:active {
    background-color: var(--support--pink--40);
  }
`;
const ItemList = styled.ul`
  display: flex;
  gap: 2rem;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  li {
    list-style: none;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active {
    border-radius: 100%;
    border: 1px solid white;
  }
`;

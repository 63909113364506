import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { nanoid } from "nanoid";

const db = collection(firebase, "/quiz");

class QuizDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, content: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/quiz", id));
    return snapshot;
  }

  async create(userid, data) {
    const docRef = await setDoc(doc(firebase, "quiz", userid), data);
    return docRef;
  }

  async update(userid, data) {
    await updateDoc(doc(firebase, "/quiz", userid), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/quiz", id));
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new QuizDataService();

const SET_ROLE ="app/role/set_role";

const initialState = "NO_ROLE";

export default function roleReducer(state = initialState, action) {
    switch(action.type)
    {
        case SET_ROLE:
            return action.role;
        default:
            return state;
    }
}


export const setRole = (role) => ({
    type: SET_ROLE,
    role: role,
  });
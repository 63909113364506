const SET_ICON = "app/multiple_choice_ui/set_icon";
const RESET_ICONS = "app/multiple_choice_ui/reset_icons";
const SET_CORRECT_ANSWER = "app/multiple_choice_ui/set_correct_answer";
const RESET_STATE = "app/reset_state";
const SET_SHOW_EXPLANATION = "app/multiple_choice_ui/set_show_explanation";
const initialState = {
  correctAnswer: undefined,
  icons: {},
  showExplanation: false,
};

export default function multipleChoiceUIReducer(state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case SET_ICON:
      if (!newState.icons) {
        newState.icons = {};
      }
      newState.icons[action.index] = action.icon;
      return newState;
    case RESET_ICONS:
      newState.icons = {};
      return newState;
    case SET_CORRECT_ANSWER:
      newState.correctAnswer = action.answer;
      return newState;
    case SET_SHOW_EXPLANATION:
      newState.showExplanation = action.showExplanation;
      return newState;
    case RESET_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
}
export const setIcon = (index, icon) => ({
  type: SET_ICON,
  index: index,
  icon: icon,
});

export const resetIcons = () => ({
  type: RESET_ICONS,
});

export const setCorrectAnswer = (answer) => ({
  type: SET_CORRECT_ANSWER,
  answer: answer,
});
export const setShowExplanation = (showExplanation) => ({
  type: SET_SHOW_EXPLANATION,
  showExplanation: showExplanation,
});

import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import {
  MdSentimentDissatisfied,
  MdSentimentNeutral,
  MdSentimentSatisfied,
  MdSentimentVeryDissatisfied,
  MdSentimentVerySatisfied,
} from "react-icons/md";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "../../context/AuthContext";
import UserFeedbackDataService from "../../services/userFeedback";

export default function FeedbackModal({ show, onClose, onConfirm }) {
  const feebackRef = useRef();
  const [feedbackType, setFeedbackType] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(null);
  const state = useSelector((state) => state);
  const { currentUser } = useAuth();
  function onSubmit(e) {
    setError("");
    if (feedbackType === null || feedbackType === 0) {
      setError("Please select feeedback type.");
      return;
    }
    if (feebackRef.current.value.length === 0) {
      setError("Please fill feeedback details.");
      return;
    }
    if (feedbackValue === null) {
      setError("Please rate your experience.");
      return;
    }
    setSuccess(true);
    UserFeedbackDataService.create({
      type: feedbackType,
      userId: currentUser.uid,
      state: state,
      experienceRate: feedbackValue,
      experienceDetails: feebackRef.current.value,
    });
    setTimeout(() => {
      onConfirm();
    }, 1500);
  }
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  }, [show]);

  function onChangeFeedbackValue(e) {
    setFeedbackValue(e.target.value);
  }

  function onChangeFeedbackType(e) {
    setFeedbackType(e.target.value);
  }
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Give Feedback!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {success && (
          <Alert variant="success">Thank you for your feedback</Alert>
        )}
        {!success && (
          <>
            {error !== "" && <Alert variant="danger">{error}</Alert>}
            <Form.Group className="mb-3">
              <select onChange={onChangeFeedbackType} className="form-select">
                <option defaultValue="0">Select a Feedback Category</option>
                <option value="help">Help to use the Hop On.</option>
                <option value="problem">Problems with the platform</option>
                <option value="question">Problems with a question</option>
                <option value="payment">Payment problems</option>
                <option value="suggestion">
                  I would like to suggest something.
                </option>
                <option value="other">Something else</option>
              </select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Use the space below to send us a message:</Form.Label>
              <Form.Control as="textarea" ref={feebackRef} rows={4} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                How would you rate your experience with Hop On so far:
              </Form.Label>
              <fieldset
                onChange={onChangeFeedbackValue}
                style={{ textAlign: "center" }}
              >
                <RatingSpan id="center-content">
                  <label htmlFor="rating-1">
                    <input type="radio" id="rating-1" name="rating" value="1" />
                    <MdSentimentVeryDissatisfied
                      size={50}
                      className="rating rating-1"
                    />
                  </label>

                  <label htmlFor="rating-2">
                    {" "}
                    <input type="radio" id="rating-2" name="rating" value="2" />
                    <MdSentimentDissatisfied
                      size={50}
                      className="rating rating-2"
                    />
                  </label>

                  <label htmlFor="rating-3">
                    {" "}
                    <input type="radio" id="rating-3" name="rating" value="3" />
                    <MdSentimentNeutral size={50} className="rating rating-3" />
                  </label>

                  <label htmlFor="rating-4">
                    <input type="radio" id="rating-4" name="rating" value="4" />
                    <MdSentimentSatisfied
                      size={50}
                      className="rating rating-4"
                    />
                  </label>

                  <label htmlFor="rating-5">
                    <input type="radio" value="5" name="rating" id="rating-5" />
                    <MdSentimentVerySatisfied
                      size={50}
                      className="rating rating-5"
                    />
                  </label>
                </RatingSpan>
              </fieldset>
            </Form.Group>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
        {!success && (
          <Button onClick={onSubmit} variant="primary">
            Send Feedback
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export const RatingSpan = styled.span`
  /* HIDE RADIO */
  width: 100%;
  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .rating {
    margin-left: 10px;
    margin-top: 5px;
  }
  /* CHECKED STYLES */
  [type="radio"]:checked + .rating-1 {
    color: #ff0d0d;
  }
  [type="radio"]:checked + .rating-2 {
    color: #ff4e11;
  }
  [type="radio"]:checked + .rating-3 {
    color: #fab733;
  }
  [type="radio"]:checked + .rating-4 {
    color: #acb334;
  }
  [type="radio"]:checked + .rating-5 {
    color: #69b34c;
  }

  .rating-1:hover {
    color: #ff0d0d;
    cursor: pointer;
  }
  .rating-2:hover {
    color: #ff4e11;
    cursor: pointer;
  }
  .rating-3:hover {
    color: #fab733;
    cursor: pointer;
  }
  .rating-4:hover {
    color: #acb334;
    cursor: pointer;
  }
  .rating-5:hover {
    color: #69b34c;
    cursor: pointer;
  }
`;

import React from "react";
import { BsPeople } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaMoneyBillWaveAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GiGraduateCap } from "react-icons/gi";
import { GoHome } from "react-icons/go";
import { MenuItem } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";

export default function AdminSidebar() {
  const navigate = useNavigate();
  return (
    <>
      <MenuItem
        data-tip="Home"
        onClick={() => navigate("home")}
        icon={<GoHome />}
      >
        <Link to="home">Home</Link>
      </MenuItem>
      <MenuItem
        data-tip="Update Profile"
        onClick={() => navigate("/updateprofile")}
        icon={<CgProfile />}
      >
        <Link to="/updateprofile">Profile</Link>
      </MenuItem>
      <hr />

      <MenuItem
        data-tip="Finance Dashboard"
        onClick={() => navigate("/dashboard/finance")}
        icon={<FaMoneyBillWaveAlt />}
      >
        <Link to="/dashboard/finance">Finance Dashboard</Link>
      </MenuItem>
      <MenuItem
        data-tip="Educational Dashboard"
        onClick={() => navigate("/dashboard/education")}
        icon={<GiGraduateCap />}
      >
        <Link to="/dashboard/education">Educational Dashboard</Link>
      </MenuItem>
      <MenuItem
        data-tip="View Students"
        onClick={() => navigate("/viewstudents")}
        icon={<BsPeople />}
      >
        <Link to="/viewstudents">View Students</Link>
      </MenuItem>

      <MenuItem
        data-tip="Sign Out"
        onClick={() => navigate("/logout")}
        icon={<FiLogOut />}
      >
        <Link to="/logout"> Sign Out</Link>
      </MenuItem>
    </>
  );
}

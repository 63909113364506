import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import styled from "styled-components";
import smartle2 from "../../assets/characters/Smartle6.png";
import smartle from "../../assets/characters/Smartle3.png";
import wan from "../../assets/characters/Wan2.png";
import hat from "../../assets/icons/Hat.png";
import medal from "../../assets/icons/Medal.png";
import essay from "../../assets/icons/Essay.png";
import brighta from "../../assets/characters/MrBrighta3.png";
import Checkbox from "../util/Checkbox";
import {
  SUBSCRIPTION_TYPE_ANNUAL,
  SUBSCRIPTION_TYPE_SEMESTER,
  SUBSCRIPTION_TYPE_TRIMESTER,
} from "../../consts";

const productPrice = { 0: 99, 1: 100, 2: 120 };

export default function SubscriptionSelectForm({
  onSelectSubscription: onSelectProductCallback = () => {},
  onSelectTrial,
  hasTrial = true,
}) {
  const [selectedProduct, setSelectedProduct] = useState(0);

  const onSelectProduct = () => {
    switch (selectedProduct) {
      case 0:
        return onSelectProductCallback(SUBSCRIPTION_TYPE_ANNUAL);
      case 1:
        return onSelectProductCallback(SUBSCRIPTION_TYPE_SEMESTER);
      case 2:
        return onSelectProductCallback(SUBSCRIPTION_TYPE_TRIMESTER);
    }
  };
  const selectProduct = (i) => {
    setSelectedProduct(i);
  };
  return (
    <>
      <div className="products">
        <div className="container">
          <SubscriptionCard
            className={selectedProduct == 0 ? "card active" : "card"}
            onClick={() => selectProduct(0)}
          >
            <div className="left">
              <img className="icon" src={medal} />
              <div>
                <div className="heading">Every Year</div>
                <div className="priceMonth">R$100,00/month</div>
                <div className="priceFull">R$200,00</div>
              </div>
            </div>
            <div className="checkbox">
              <div class="checkbox-wrapper-40">
                <label>
                  <input type="checkbox" checked={selectedProduct == 0} />
                  <span class="checkbox"></span>
                </label>
              </div>
            </div>
          </SubscriptionCard>
          <SubscriptionCard
            className={selectedProduct == 1 ? "card active" : "card"}
            onClick={() => selectProduct(1)}
          >
            <div className="padded">
              <div className="heading">Every 6 months</div>
              <div className="priceMonth">R$100,00/month</div>{" "}
              <div className="priceFull">R$200,00</div>
            </div>

            <div className="checkbox">
              {" "}
              <div class="checkbox-wrapper-40">
                <label>
                  <input type="checkbox" checked={selectedProduct == 1} />
                  <span class="checkbox"></span>
                </label>
              </div>
            </div>
          </SubscriptionCard>
          <SubscriptionCard
            className={selectedProduct == 2 ? "card active" : "card"}
            onClick={() => selectProduct(2)}
          >
            <div className="padded">
              <div className="heading">Every 3 months</div>
              <div className="priceMonth">R$100,00/month</div>{" "}
              <div className="priceFull">R$200,00</div>
            </div>

            <div className="checkbox">
              <div class="checkbox-wrapper-40">
                <label>
                  <input type="checkbox" checked={selectedProduct == 2} />
                  <span class="checkbox"></span>
                </label>
              </div>
            </div>
          </SubscriptionCard>
        </div>
      </div>

      <div className="action">
        {hasTrial && (
          <>
            <button className="trial" onClick={onSelectTrial}>
              I want to try before deciding
            </button>
            <>or</>
          </>
        )}

        <Button variant="success" onClick={onSelectProduct}>
          Continue
        </Button>
      </div>
    </>
  );
}

export const SubscriptionCard = styled.div`
  height: ${(props) => (props.height ? props.height : "7rem")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--primary--20);
  border-radius: 1rem;
  padding: 1rem;
  border: var(--primary--30) solid 3px;

  &:hover {
    box-shadow: 0 0 5px black;
  }
  &.active {
    box-shadow: 0 0 3px black;
    background-color: var(--support--orange--30);
    border: var(--support--orange--40) solid 3px;
  }
  .heading {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: -0.1rem;
  }
  .priceMonth {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }
  .padded {
    padding-left: 3.5rem;
  }

  .checkbox {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }

  .no-margin-right {
    margin-right: 0;
  }
  .icon {
    width: 3.3rem;
    height: 3.3rem;
    transform: translateY(-1rem);
    margin-right: 0.3rem;
  }
  @media (max-width: 1000px) {
    min-width: 25rem;
  }
`;

export const CardContainer = styled.div`
  height: 40vh;
  display: flex;
  flex-direction: column;

  justify-content: center;

  .products {
    margin-top: 2rem;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  .container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 2rem;
  }

  .left {
    display: flex;
  }
  .trial {
    all: unset;
    display: inline;
    color: var(--primary--70);
    margin-right: 2rem;
  }
  .trial:hover {
    color: var(--primary--90);
  }

  .action {
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    text-align: center;
    color: var(--primary--70);
    transform: translateX(-5rem);
  }
  .action .btn {
    margin-left: 2rem;
  }
  .trial {
    border: 4px var(--primary--20) solid;
    border-radius: 2rem;
    padding: 0.4rem 1rem;
  }
  @media (max-width: 1000px) {
    margin-bottom: 2rem;
    height: 100%;
    .products {
      height: auto;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    .container {
      grid-template-columns: auto;
      grid-row-gap: 1rem;
    }
    .action {
      transform: translateX(-3rem);
    }
  }
`;

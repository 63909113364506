import {
  runTutorial,
  setStepIndex,
  stopTour,
  stopTutorial,
} from "../redux/tutorial";

export const homeTutorialCallback = (
  data,
  navigate,
  dispatch,
  onEndCallback,
  courseId
) => {
  const { action, index, lifecycle, type } = data;
  console.log(data);
  if (type === "step:after") {
    if (index === 0) {
      dispatch(stopTutorial());
      dispatch(setStepIndex(1));
      navigate("/c/" + courseId);
    } else if (index === 1) {
      if (action === "next") {
        dispatch(stopTutorial());
        dispatch(setStepIndex(2));
        navigate("/c/" + courseId + "/1");
      } else if (action === "prev") {
        dispatch(setStepIndex(0));
        dispatch(stopTutorial());
        navigate("/home");
      }
    } else if (index >= 1) {
      if (index === 2 && action === "prev") {
        dispatch(setStepIndex(1));
        dispatch(stopTutorial());
        navigate("/c/" + courseId);
      } else if (index >= 2 && action === "prev") {
        dispatch(runTutorial());
        dispatch(setStepIndex(index - 1));
      } else if (action === "next") {
        dispatch(runTutorial());
        dispatch(setStepIndex(index + 1));
      }
    }
  }
  if (index === 4 && lifecycle === "complete") {
    onEndCallback();
  }
  if (action == "close") {
    onEndCallback();
    dispatch(stopTutorial());
    dispatch(setStepIndex(0));
    dispatch(stopTour());
  }
};
export const homeTutorialSteps = [
  {
    target: ".tutorial1",
    content: (
      <>
        <p>Welcome to your personal control room!</p>
        <p>Each path has it's own features and content. </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#tutorialMiddle",
    content: (
      <>
        <p>We are glad you decided to HOP ON with us on this journey!</p>
        <p>
          This is what your Boost Your English journey looks like: there are
          islands of subjects to explore, be free to begin by any of them!
        </p>
      </>
    ),
  },
  {
    target: "#tutorialStart",
    content: (
      <>
        <p>
          {" "}
          Each subject has classes that teach you the skills you need to
          succeed.
        </p>
        <p>This is where you are now, the beginning of your journey.</p>
      </>
    ),
  },
  {
    target: "#tutorialMiddle",
    content: (
      <>
        <p>
          These stars show you how well you are doing in the subject, let's try
          to fill them all?
        </p>
      </>
    ),
  },
  {
    target: "#tutorialEnd",
    content: (
      <>
        <p>
          {" "}
          In the end, there will be a timed test for you to measure your skills
        </p>
      </>
    ),
  },
];

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import TimeMe from "timeme.js";

import { NOTIFICATION_PAGEVIEW } from "../consts";
import { useAuth } from "../context/AuthContext";
import NotificationDataService from "../services/notification";
import FeedbackIcon from "./Feedback/FeedbackIcon";
import Sidebar from "./Sidebar/Sidebar";
import "../styles/App.scss";
import ReactTooltip from "react-tooltip";
import Joyride, { CallBackProps } from "react-joyride";
import {
  freeTestTutorialSteps,
  freeTestTutorialCallback,
} from "./TutorialFreeTestStudentHome";
import { homeTutorialCallback, homeTutorialSteps } from "./TutorialStudentHome";
import {
  meetingTutorialCallback,
  meetingTutorialSteps,
} from "./TutorialStudentMeeting";
import { useDispatch, useSelector } from "react-redux";
import UserProfileDataService from "../services/userProfile";
import { stopTour } from "../redux/tutorial";
import "react-toastify/dist/ReactToastify.min.css";
import {
  setHasTutorialFreePlan,
  setHasTutorialPaidPlan,
} from "../redux/profile";
export default function AppWrapper() {
  const { getProfileInfo, currentUser } = useAuth();
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [userName, setUserName] = useState("");

  const tutorialID = useSelector((state) => state.tutorial.id);
  const run = useSelector((state) => state.tutorial.run);
  const stepIndex = useSelector((state) => state.tutorial.stepIndex);
  const tutorialCourseId = useSelector((state) => state.tutorial.courseId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCurrentTutorialSteps = () => {
    console.log(tutorialID);
    switch (tutorialID) {
      case "FREE_TEST":
        return freeTestTutorialSteps;
      case "meeting":
        return meetingTutorialSteps;
      default:
        return homeTutorialSteps;
    }
  };

  const getCurrentTutorialCallback = () => {
    console.log(tutorialID);
    switch (tutorialID) {
      case "FREE_TEST":
        return freeTestTutorialCallback;
      case "meeting":
        return meetingTutorialCallback;
      default:
        return homeTutorialCallback;
    }
  };
  useEffect(() => {
    TimeMe.initialize({
      currentPageName: "my-home-page", // current page
      idleTimeoutInSeconds: 30, // seconds
    });
    const fetchProfile = async () => {
      var profile = await getProfileInfo();
      if (profile.exists()) {
        var data = profile.data();
        setProfilePictureURL(data.profilePicture);
        setUserName(data.firstName);
      }
    };
    fetchProfile();

    return () => {
      if (currentUser) {
        NotificationDataService.create(NOTIFICATION_PAGEVIEW, {
          userId: currentUser.uid,
          internal: true,
          page: window.location.pathname,
          timeSpent: TimeMe.getTimeOnCurrentPageInSeconds(),
        });
      }
    };
  }, []);

  function onEndCallback() {
    dispatch(stopTour());
    if (tutorialID != "meeting" && tutorialID != "FREE_TEST") {
      UserProfileDataService.update(currentUser.uid, {
        hasTutorialPaidPlan: true,
      }).then(() => navigate("/home"));
      dispatch(setHasTutorialPaidPlan(true));
    } else if (tutorialID == "FREE_TEST") {
      UserProfileDataService.update(currentUser.uid, {
        hasTutorialFreePlan: true,
      });
      dispatch(setHasTutorialFreePlan(true));
    }
  }
  return (
    <>
      <ReactTooltip />
      {/*
      <Navbar style={{ background: "#002f6c", color: var(--white-text) }} expand="xs">
        <Container fluid className="d-flex">
          <Col style={{ textAlign: "right" }}>
            Welcome, {userName === "" ? "Student" : userName} !
            <img
              src={
                profilePictureURL !== ""
                  ? profilePictureURL
                  : EmptyProfilePicture
              }
              alt="Foto de perfil"
              className="img-profile-small"
            />
          </Col>
        </Container>
      </Navbar>
      */}
      <div className={`app`}>
        <Sidebar />
        <Joyride
          callback={(data) =>
            getCurrentTutorialCallback()(
              data,
              navigate,
              dispatch,
              onEndCallback,
              tutorialCourseId
            )
          }
          continuous
          run={run}
          stepIndex={stepIndex}
          steps={getCurrentTutorialSteps()}
          styles={{ options: { zIndex: 99999 } }}
        />
        <ToastContainer />
        <AppContainer fluid>
          <Outlet />
          <FeedbackIcon />

          <AppbarSpacer />
        </AppContainer>
      </div>
    </>
  );
}

const AppContainer = styled(Container)``;
const AppbarSpacer = styled.div`
  height: 82px;
  width: 100%;
`;

import { firebase } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { nanoid } from "nanoid";

const db = collection(firebase, "/userNotification");

class UserNotificationDataService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map((doc) => ({ id: doc.id, content: doc.data() }));
  }

  async get(id) {
    const snapshot = await getDoc(doc(firebase, "/userNotification", id));
    return snapshot;
  }

  async getFromTypeList(notification_type) {
    const q = await getDocs(
      query(
        collection(firebase, "/userNotification"),
        where("type", "in", notification_type)
      )
    );
    return q;
  }

  async getUnreadChatNotification(userId) {
    const snapshot = await getDoc(doc(firebase, "/userNotification", userId));
    return snapshot.data().unreadMessages;
  }
  async updateUnreadChatNotification(userId, data) {
    const snapshot = await getDoc(doc(firebase, "/userNotification", userId));
    if (!snapshot.exists()) {
      await setDoc(doc(firebase, "userNotification", userId), {
        unreadMessages: data,
      });
    } else {
      await this.update(userId, { unreadMessages: data });
    }
  }
  async newNotification(from, to, notification_type, data) {
    const snapshot = await getDoc(doc(firebase, "/userNotification", to));
    if (!snapshot.exists()) {
      await setDoc(doc(firebase, "userNotification", to), {
        unreadMessages: {},
      });
    }

    const obj = {
      [Date.now()]: {
        from: from,
        to: to,
        data: data ?? {},
        type: notification_type,
        unread: true,
      },
    };
    const docRef = await updateDoc(doc(firebase, "userNotification", to), obj);
    return docRef;
  }

  async update(id, data) {
    await updateDoc(doc(firebase, "/userNotification", id), data);
  }

  async delete(id) {
    return await deleteDoc(doc(firebase, "/userNotification", id));
  }

  deleteAll() {
    return;
  }

  findByTitle(title) {
    return;
  }
}

export default new UserNotificationDataService();

const SET_ACTIVITY = "/app/student_activity/set_activity";
const SET_ALL_ACTIVITY = "/app/student_activity/set_all_activity";
const RESET_STATE = "app/reset_state";

const initialState = {};

export default function studentActivityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVITY:
      const newState = { ...state };
      newState[action.id] = action.activity;
      return newState;
    case SET_ALL_ACTIVITY:
      return { ...action.activityList };
    case RESET_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
}

export const setStudentActivity = (id, activity) => ({
  type: SET_ACTIVITY,
  id: id,
  activity: activity,
});

export const setAllStudentActivity = (activityList) => ({
  type: SET_ALL_ACTIVITY,
  activityList: activityList,
});
